import React from 'react'
import PropTypes from 'prop-types'

const BoxBody = ({
  children,
  className
}) => {
  return (
    <div className={`fb-box__body ${className || ''}`}>
      {children}
    </div>
  )
}

BoxBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

BoxBody.displayName = 'Box.Body'

export default BoxBody
