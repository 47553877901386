const _min = require('lodash/min')
const _max = require('lodash/max')
const Base = require('./base')
const { select, selectAll } = require('d3-selection')
const {scaleBand, scaleLinear, scaleOrdinal} = require('d3-scale')
const {axisTop, axisLeft} = require('d3-axis')

class CompareBarChartNegative extends Base {
  drawChart (props) {
    const {
      data,
      element,
      colors,
      graphLeft,
      groups,
      fontSize
    } = this.props

    const {
      width: initialWidth,
      height: initialHeight,
      range
    } = props

    if (!element) return

    const strokeWidth = 1.5
    const margin = { top: 0, bottom: 20, left: 35, right: 20 }
    const width = initialWidth - margin.left - margin.right - (strokeWidth * 2)
    const height = initialHeight - margin.top - margin.bottom
    // chart setup
    const svg = select(element)
      .attr('width', initialWidth)
      .attr('height', initialHeight)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .classed('svg-content-responsive', true)
      .classed('svg-container', true)
      .classed('svg-content-responsive', true)
      .classed('compare-chart-negative', true)

    const g = svg.append('g').attr('transform', `translate(${graphLeft || '30'},30)`)

    // y position calculation function
    const y = scaleLinear()
      .domain([_min(range), _max(range)])
      .range([height, 0])

    const x0 = scaleBand()
      .domain(groups)// domain defined below
      .rangeRound([0, width])
      .padding(0.2)

    const x1 = scaleBand() // domain and range defined below
      .padding(0.05)

    const z = scaleOrdinal()
      .range(colors)

    // reference to the y axis
    // axisLeft put labels on left side
    // ticks(n) refers to # of increment marks on the axis
    const yAxis = axisLeft(y)
      .ticks(4)
      .tickFormat(d => d === 0 ? '' : `${d / 1000000}M`)

    const subCategories = Object.keys(data[0]).slice(1)

    // array of quarterly value names, fitted in the available bottom categories (x0.bandwidth())
    x1.domain(subCategories).rangeRound([0, x0.bandwidth()])

    // Add bar chart
    const selection = g.selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => 'translate(' + x0(d.group) + ',0)')
      .selectAll('rect')
      .data((d) => subCategories.map((key) => {
        return { key: key, value: d[key] }
      }))
      .enter()
      .append('rect')
      .attr('x', d => x1(d.key))
      // If the value is negative, put the top left corner of the rect bar on the zero line
      .attr('y', d => (d.value < 0 ? y(0) : y(d.value)))
      .attr('width', x1.bandwidth())
      .attr('height', d => Math.abs(y(d.value) - y(0)))
      .attr('fill', d => z(d.key))

    // can not nest the text element inside the rect element !
    selection.selectAll('text')
      .data(d => subCategories.map((key) => {
        return { key: key, value: d[key] }
      }))
      .enter()
      .append('text')
      .attr('x', d => x1(d.key))
      // offset the position of the y value (positive / negative) to have the text over/under the rect bar
      .attr('y', d => {
        d.value = d.value || 0
        return d.value <= 0 ? y(0) - (y(4) - (Math.abs(y(d.value) - y(0)) + 20)) : y(d.value) - 10
      })
      .style('fill', '#666666')
      .style('font-size', '1.25em')

    // add the y-axis - notice it does not have css class 'axis'
    g.append('g')
      .call(yAxis)

    g.append('g')
      .attr('transform', 'translate(0,0)')
      .call(axisTop(x0).tickSize(0))

    // idenitfy zero line on the y axis.
    g.append('line')
      .attr('y1', y(0))
      .attr('y2', y(0))
      .attr('x1', 0)
      .attr('x2', width)
      .attr('stroke', 'black')

    selectAll('path.domain')
      .style('stroke', '#BABABA')

    selectAll('line')
      .style('stroke', '#BABABA')

    selectAll('text')
      .style('fill', '#666666')
      .style('font-size', '14px')

    selectAll('.compare-chart-negative text')
      .style('fill', '#27303D')
      .style('font-size', fontSize)
      .call(this.wrap.bind({multiply: 5}), x0.bandwidth())
  }
}

module.exports = (props) => {
  const chart = new CompareBarChartNegative(props)
  return chart.init()
}
