import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

import Carousel from '../Carousel'
import StatementAvatar from '../StatementAvatar'
import StatementCard from '../StatementCard'
import Text from '../Text'
import HeroCta from '../HeroCta'

class SuccessStories extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeIdx: this.props.withOpenItem && 0
    }
  }

  handleOnClick = (idx) => {
    const { activeIdx } = this.state

    if (activeIdx === idx) {
      this.setState({ activeIdx: null })
      return
    }

    this.setState({ activeIdx: idx })
  }

  renderStatementCard = () => {
    const { activeIdx } = this.state
    const {stories} = this.props

    if (activeIdx === null) {
      return
    }
    const item = stories[activeIdx]
    if (_.isEmpty(item)) {
      return
    }

    const { name, quote, pharmacyName, url } = item.node

    return <StatementCard name={name} intro={quote} location={pharmacyName} url={url} />
  }

  render () {
    const {
      className,
      stories,
      withSignup,
      isThemed
    } = this.props

    const {
      activeIdx
    } = this.state

    return (
      <div
        className={classnames(
          'fb-success-stories',
          {'fb-success-stories--themed': isThemed},
          className
        )}
      >
        <Carousel withTheme={isThemed}>
          { stories.map((item, idx) => (<StatementAvatar key={item.node.name} isExpanded={activeIdx === idx} onClick={() => this.handleOnClick(idx)} name={item.node.name} intro={item.node.quote} location={item.node.pharmacyName} image={item.node.picture} />))}
        </Carousel>
        { this.renderStatementCard() }

        {withSignup && (
          <Fragment>
            <Text className='fb-success-stories__subtitle' size='xlarge'>Join 500+ pharmacies in accessing affordable pay-as-you-sell medicines</Text>
            <HeroCta url='/signup/' buttonText='Sign up' />
          </Fragment>
        )}
      </div>
    )
  }
}

SuccessStories.propTypes = {
  /**
   * Name of the statement giver.
   */
  name: PropTypes.string,
  stories: PropTypes.array,
  withSignup: PropTypes.bool,
  isThemed: PropTypes.bool,
  withOpenItem: PropTypes.bool
}

SuccessStories.defaultProps = {
  withSignup: true,
  isThemed: true,
  withOpenItem: true
}

export default SuccessStories
