import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Img from 'gatsby-image'

import Card from '../Card'
import FieldBrandIcon from '../../icons/FieldBrandIcon'
import Text from '../Text'
import UppercaseLabel from '../UppercaseLabel'
import Video from '../Video'

let wordIndex = 0

const typingEffect = (words, container) => {
  let word = words[wordIndex].split('')

  const loopTyping = () => {
    if (word.length > 0) {
      container.innerHTML += word.shift()
    } else {
      setTimeout(() => deletingEffect(words, container), 2000)
      return false
    }
    setTimeout(loopTyping, 50)
  }
  loopTyping()
}

const deletingEffect = (words, container) => {
  let word = words[wordIndex].split('')
  const loopDeleting = () => {
    if (word.length > 0) {
      word.pop()
      container.innerHTML = word.join('')
    } else {
      if (words.length > (wordIndex + 1)) {
        wordIndex++
      } else {
        wordIndex = 0
      }
      typingEffect(words, container)
      return false
    }
    setTimeout(loopDeleting, 50)
  }
  loopDeleting()
}

class Hero extends React.Component {
  componentDidMount () {
    // objectFit fallback for IE and Edge

    if ('objectFit' in document.documentElement.style === false) {
      // checking DOMContentLoaded shouldn't be needed but withouth it tests are failing without it
      // - an error is thrown by Hero.stories.js because `container` is null
      document.addEventListener('DOMContentLoaded', () => {
        var container = this.imageContainer
        var imageSource = container.querySelector('img').src

        container.querySelector('img').style.display = 'none'
        container.style.backgroundSize = 'cover'
        container.style.backgroundImage = 'url(' + imageSource + ')'
        container.style.backgroundPosition = 'center center'
      })
    }

    const words = this.props.titleRotationAddOn

    if (words) {
      typingEffect(words, document.getElementById('HeroWordRotation'))
    }
  }

  render () {
    const {
      video,
      image,
      imageCutOff,
      label,
      title,
      titleRotationAddOn,
      intro,
      cardLabel,
      cardTitle,
      cardBody,
      cardButtonText,
      cardButtonLink,
      titleSize,
      className
    } = this.props

    return (
      <div
        className={classnames(
          'fb-hero',
          {'fb-hero--angled': imageCutOff === 'angled' || (!imageCutOff && title && (cardBody || cardTitle) && !intro)},
          {'fb-hero--top': imageCutOff === 'top' || (!imageCutOff && title && (cardBody || cardTitle) && intro)},
          {'fb-hero--cut': title && (cardBody || cardTitle)},
          className
        )}
      >
        {video && (
          <Video>
            {video}
          </Video>)}
        {image && !video && (
          <div className='fb-hero__image' ref={node => (this.imageContainer = node)}>
            {image.fluid && (
              <Img alt={title} fluid={image.fluid} loading='eager' />
            )}
          </div>
        )}
        {(title || intro) && (
          <div className='fb-hero__overlay'>
            {intro && intro.childMarkdownRemark && (
              <Text size='medium' className='fb-hero__intro' dangerouslySetInnerHTML={{__html: intro.childMarkdownRemark.html}} />
            )}
            <div className='fb-hero__heading'>
              {label && <UppercaseLabel labelText={label} className='fb-hero__label' />}

              <Text.Heading
                tag='h1'
                size={titleSize === 'medium' ? 'xlarge' : 'huge'}
                className='fb-hero__title'
              >
                {titleRotationAddOn && (
                  <div className='fb-hero__title-rotation-addon'>
                    <span id='HeroWordRotation' />
                  </div>
                )}
                {title}
              </Text.Heading>

              <FieldBrandIcon color='accent' className='fb-hero__icon' width='20px' />
            </div>
          </div>
        )}

        {(cardTitle || cardBody) && (
          <div className='fb-hero__card'>
            <Card>
              <Card.Content
                label={cardLabel}
                headline={cardTitle}
                buttonText={cardButtonText}
                buttonLink={cardButtonLink}
              >
                {(cardBody && cardBody.childMarkdownRemark) && (
                  <div
                    dangerouslySetInnerHTML={{ __html: cardBody.childMarkdownRemark.html }}
                  />
                )}
              </Card.Content>
            </Card>
          </div>
        )}

      </div>
    )
  }
}

Hero.propTypes = {
  /**
   * Background image, must have gatsby image fluid object.
   */
  image: PropTypes.object,
  /**
   * Optional label above heading.
   */
  label: PropTypes.string,
  /**
   * Heading text.
   */
  title: PropTypes.string,
  /**
   * If an intro text is passed, it will render above image
   */
  intro: PropTypes.shape({
    intro: PropTypes.string
  }),
  /**
   * Size variant for title
   */
  size: PropTypes.oneOf(['large', 'medium'])
}

Hero.defaultProps = {
  size: 'large'
}

export default Hero
