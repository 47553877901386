const COUNTRIES_GEO_ID = {
  Nigeria: 'country:ng',
  Kenya: 'country:ke'
}

const COUNTRIES_STATES = {
  Nigeria: ['Delta', 'Edo', 'Enugu', 'FCT', 'Kaduna', 'Kano', 'Kwara', 'Lagos', 'Nasarawa', 'Ogun', 'Oyo', 'Rivers', 'Akwa Ibom', 'Osun', 'Ekiti', 'Ondo', 'Katsina', 'Bauchi'],
  Kenya: ['Coastal', 'Nairobi', 'North Rift', 'Nyanza', 'South Rift', 'Central Province']
}

export {
  COUNTRIES_GEO_ID,
  COUNTRIES_STATES
}
