import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Video = ({
  children,
  className
}) => (
  <div className={classnames(
    'fb-video',
    className
  )}>
    {children}
  </div>
)

Video.propTypes = {
  /**
   * Pass youtube embed tag
   */
  children: PropTypes.any,
  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string
}

export default Video
