import React from 'react'

const Checkmark = ({
  size = '100%',
  className
}) => {
  return (
    <svg viewBox='0 0 19 15' aria-labelledby='title' aria-hidden='true' className={className} width={size} height={size}><path d='M19.029 2.848c0 .328-.115.606-.344.835L8.127 14.241c-.229.23-.507.344-.835.344-.327 0-.605-.115-.834-.344L.344 8.127A1.137 1.137 0 0 1 0 7.292c0-.327.115-.605.344-.834l1.67-1.67c.229-.23.507-.344.834-.344.328 0 .606.115.835.344l3.61 3.622L15.345.344c.23-.23.507-.344.835-.344.327 0 .605.115.835.344l1.67 1.67c.228.229.343.507.343.834z' /></svg>
  )
}

export default Checkmark
