import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Text from '../Text'
import Button from '../Button'
import UppercaseLabel from '../UppercaseLabel'

const ImageTextBlock = ({
  headline,
  text,
  labelText,
  buttonLink,
  buttonText,
  image,
  imageOnRight,
  onColor,
  isConnectedPanel
}) => {
  let buttonColor = 'brand'
  if (onColor === 'brand') buttonColor = 'accent'

  return (
    <div
      className={classnames(
        'fb-image-text-block',
        {'fb-image-text-block--reverse': imageOnRight},
        {'fb-image-text-block--on-accent': onColor === 'accent'},
        {'fb-image-text-block--on-light': onColor === 'grey' || onColor === 'white'},
        {'fb-image-text-block--connected': isConnectedPanel}
      )}
    >
      {(image && image.fluid) &&
        <div className='fb-image-text-block__image'>
          <Img alt={headline} fluid={image.fluid} />
        </div>
      }
      <div className='fb-image-text-block__body'>
        {labelText && <UppercaseLabel labelText={labelText} />}
        {headline && (
          <Text.Heading size='xlarge' className='fb-image-text-block__headline'>{headline}</Text.Heading>
        )}
        {text && text.childMarkdownRemark && (
          <Text size='large' className='fb-image-text-block__text' dangerouslySetInnerHTML={{__html: text.childMarkdownRemark.html}} />
        )}
        {buttonLink && (
          <Button
            href={buttonLink}
            className='fb-image-text-block__button'
            color={buttonColor}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}

ImageTextBlock.propTypes = {
  /**
   * Text body.
   */
  text: PropTypes.object.isRequired,
  /**
   * Optional small uppercase label above the headline.
   */
  labelText: PropTypes.string
}

export default ImageTextBlock
