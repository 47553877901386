const _max = require('lodash/max')
const Base = require('./base')

const { select, selectAll } = require('d3-selection')
const {scaleBand, scaleLinear, scaleOrdinal} = require('d3-scale')
const {axisBottom, axisLeft} = require('d3-axis')

class CompareBarChart extends Base {
  drawChart (props) {
    const {
      element,
      groups,
      subGroups,
      graphLeft,
      data,
      colors,
      onHover,
      noTicks,
      allowHover,
      fontSize,
      tickLabel
    } = this.props
    const {
      range,
      width: initialWidth,
      height: initialHeight
    } = props
    if (!element) return

    const strokeWidth = 1.5
    const margin = { top: 0, bottom: 20, left: 30, right: 20 }
    const width = initialWidth - margin.left - margin.right - (strokeWidth * 2)
    const height = initialHeight - margin.top - margin.bottom
    const svg = select(element)
      .append('svg')
      .attr('width', initialWidth + margin.left + margin.right)
      .attr('height', initialHeight)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .classed('svg-content-responsive', true)
      .classed('svg-container', true)
      .classed('svg-content-responsive', true)
      .classed('compare-chart', true)
      .append('g')
      .attr('transform', `translate(${graphLeft || 5},0)`)

    // Add X axis
    const x = scaleBand()
      .domain(groups)
      .range([0, width + 50])
      .padding(0.2)

    svg.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(axisBottom(x).tickSize(0))

    // Add Y axis
    const y = scaleLinear()
      .domain([0, _max(range)])
      .range([height, 0])

    const yAxis = axisLeft(y)
      .ticks(3.5)
      .tickFormat(d => `${tickLabel || ''}${d / 1000000}M`)

    if (noTicks) {
      yAxis
        .tickSize(0)
        .tickFormat(d => '')
    }

    svg.append('g').call(yAxis)

    // Another scale for subgroup position?
    const xSubgroup = scaleBand()
      .domain(subGroups)
      .range([0, x.bandwidth()])
      .padding(0.05)

    // color palette = one color per subgroup
    const color = scaleOrdinal()
      .domain(subGroups)
      .range(colors)

    svg.append('g')
      .selectAll('g')
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (d) => 'translate(' + x(d.group) + ',0)')
      .selectAll('rect')
      .data((d) => subGroups.map((key) => {
        return { key: key, value: d[key], group: d.group }
      }))
      .enter().append('rect')
      .attr('x', (d) => xSubgroup(d.key))
      .attr('y', (d) => y(d.value))
      .attr('width', xSubgroup.bandwidth())
      .attr('height', (d) => height - y(d.value))
      .attr('fill', (d) => color(d.key))

    selectAll('path.domain')
      .style('stroke', '#BABABA')

    selectAll('.compare-chart text')
      .style('fill', '#27303D')
      .style('font-size', fontSize)
      .call(this.wrap, x.bandwidth())

    if (allowHover) {
      const tooltip = select('body').append('div').attr('class', 'value-calculator-tool-tip')
      svg
        .selectAll('rect')
        .on('mousemove', (event, d) => {
          const pageX = event.pageX
          const pageY = event.pageY
          tooltip
            .style('left', pageX - 50 + 'px')
            .style('top', pageY - 70 + 'px')
            .style('display', 'inline-block')
          if (onHover) {
            onHover(event, d, (result) => {
              tooltip.html(result)
            })
          } else {
            tooltip.html(d.value)
          }
        })
        .on('mouseout', () => {
          tooltip.style('display', 'none')
        })
    }
  }
}

module.exports = (props) => {
  const chart = new CompareBarChart(props)
  return chart.init()
}
