import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Radio = ({
  value,
  label,
  isSelected,
  withUppercase,
  ...otherProps
}) => {
  return (
    <label
      className={classnames(
        'fb-radio',
        {'fb-radio--selected': isSelected},
        {'fb-radio--uppercase': withUppercase}
      )}
      key={`option-${value}`}
    >
      <input
        value={value}
        className='fb-radio__input'
        type='radio'
        checked={isSelected}
        {...otherProps}
      />
      <span className='fb-radio__faux-radio' />
      <span className='fb-radio__label'>
        {label}
      </span>
    </label>
  )
}

Radio.propTypes = {
  /**
   * A radio button will always be part of a group.
   * This is the value of the individual option in the group.
   */
  value: PropTypes.string.isRequired,

  /**
   * Label of the option, e.g. for a sorting selection: Alphabetical
   */
  label: PropTypes.string.isRequired,
  /**
   * Set to mark as the currently selected Radio in a group
   */
  isSelected: PropTypes.bool,
  /**
   * If the label should be uppercase
   */
  withUppercase: PropTypes.bool
}

Radio.defaultProps = {
  isSelected: false,
  withUppercase: true
}

export default Radio
