import React from 'react'
import PropTypes from 'prop-types'

import FieldBrandIcon from '../../icons/FieldBrandIcon'
import Text from '../Text'
import UppercaseLabel from '../UppercaseLabel'

const PullQuote = ({labelText, bodyText}) => (
  <div className='fb-pull-quote'>
    {labelText && (
      <UppercaseLabel labelText={labelText} className='fb-pull-quote__label' />
    )}
    {bodyText && bodyText.childMarkdownRemark && (
      <Text size='xxlarge' className='fb-pull-quote__body' dangerouslySetInnerHTML={{__html: bodyText.childMarkdownRemark.html}} />
    )}
    <FieldBrandIcon color='black' width='11px' />
  </div>
)

PullQuote.propTypes = {
  /**
   * Text body.
   */
  bodyText: PropTypes.string.isRequired,

  /**
   * Optional small uppercase label.
   */
  labelText: PropTypes.string
}

export default PullQuote
