import React from 'react'

const Chevron = ({direction = 'up'}) =>
  <span className='icon-container'>
    <svg className={`icon icon--direction-${direction}`} width='1em' height='1em' viewBox='0 0 9 13.4' aria-labelledby='title' aria-hidden='true'>
      <title id='title'>Chevron {direction}</title>
      <path d='M8.648 6.852L2.85 12.65q-.147.147-.35.147t-.353-.148L.85 11.35Q.702 11.204.702 11t.148-.352L4.998 6.5.85 2.352Q.702 2.204.702 2t.148-.352L2.147.35Q2.295.204 2.5.204t.35.148L8.65 6.15q.148.148.148.352t-.148.352z' />
    </svg>
  </span>

export default Chevron
