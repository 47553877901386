import React from 'react'

const ToolTipIcon = ({ className }) => (
  <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' className={className}>
    <title>ToolTip Info Icon</title>
    <defs>
      <filter id='filter-1'>
        <feColorMatrix in='SourceGraphic' type='matrix' values='0 0 0 0 0.729412 0 0 0 0 0.729412 0 0 0 0 0.729412 0 0 0 1.000000 0' />
      </filter>
    </defs>
    <g id='Value-Calculator' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='ic-info-48px' transform='translate(-204.000000, -953.000000)' filter='url(#filter-1)'>
        <g transform='translate(204.000000, 953.000000)'>
          <polygon id='Path' points='0 0 16 0 16 16 0 16' />
          <path d='M8.42105263,0.842105263 C4.69894737,0.842105263 1.68421053,3.85684211 1.68421053,7.57894737 C1.68421053,11.3010526 4.69894737,14.3157895 8.42105263,14.3157895 C12.1431579,14.3157895 15.1578947,11.3010526 15.1578947,7.57894737 C15.1578947,3.85684211 12.1431579,0.842105263 8.42105263,0.842105263 Z M9.09473684,10.9473684 L7.74736842,10.9473684 L7.74736842,6.90526316 L9.09473684,6.90526316 L9.09473684,10.9473684 Z M9.09473684,5.55789474 L7.74736842,5.55789474 L7.74736842,4.21052632 L9.09473684,4.21052632 L9.09473684,5.55789474 Z' id='Shape' fill='#000000' fillRule='nonzero' />
        </g>
      </g>
    </g>
  </svg>
)

export default ToolTipIcon
