import React from 'react'
import Icon from './Icon'
const ToolTip = ({ text }) => {
  return (
    <div className='fb-input--tooltip'>
      <span className='fb-input--tooltip-icon'>
        <Icon />
      </span>
      <span className='fb-input--tooltip-text'>{text}</span>
    </div>
  )
}

export default ToolTip
