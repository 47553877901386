import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import UppercaseLabel from '../UppercaseLabel'

const ShortTextBlock = ({bodyText, labelText}) => {
  return (
    <div className='fb-short-text-block'>
      {labelText && <UppercaseLabel labelText={labelText} />}
      {bodyText && bodyText.childMarkdownRemark && (
        <Text size='xxlarge' className='fb-short-text-block__body' dangerouslySetInnerHTML={{__html: bodyText.childMarkdownRemark.html}} />
      )}
    </div>
  )
}

ShortTextBlock.propTypes = {
  /**
   * Text body.
   */
  bodyText: PropTypes.object.isRequired,
  /**
   * Optional small uppercase label above the headline.
   */
  labelText: PropTypes.string
}

export default ShortTextBlock
