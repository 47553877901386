import React from 'react'

import Page from '../Page'
import LongTextBlock from '../../TextBlock'

const LongText = ({
  id,
  sectionLabel,
  isConnectedPanel,
  nextBlockTransparent,
  panelLabel,
  panelLabelColor,
  ...blockProps
}) => {
  return (
    <Page.Panel noPadding key={`panel${id}`}>
      {(sectionLabel || panelLabel) && (
        <Page.Panel.Label color={panelLabelColor}>
          {sectionLabel || panelLabel}
        </Page.Panel.Label>
      )}
      <LongTextBlock {...blockProps} isConnectedPanel={isConnectedPanel} nextBlockTransparent={nextBlockTransparent} />
    </Page.Panel>
  )
}

export default LongText
