import React from 'react'
import Page from '../../Page'
import Text from '../../Text'
import CalculatorSimple from './CalculatorSimple'

// todo: Temporary hardcoded Content need to put into contentful
class CalculatorFormHomePage extends React.Component {
  render () {
    return (
      <Page.Panel color='grey' texture='amoeba'>
        <div className='fb-calculator-home'>
          <div className='fb-calculator-home__text'>
            <Text.Heading size='xlarge' className='fb-calculator-home__title'>
              What could Shelf Life do for your pharmacy?
            </Text.Heading>
            <Text size='large' className='fb-calculator-home__description'>
              Find out what reducing stockouts and eliminating expiry, expanding your offering, and freeing up cash can do for your business.
            </Text>
          </div>
          <div className='fb-calculator-home__form'>
            <CalculatorSimple />
          </div>
        </div>
      </Page.Panel>
    )
  }
}

export default CalculatorFormHomePage
