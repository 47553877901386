import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Arrow from '../../icons/Arrow'

class StatementCard extends React.Component {
  render () {
    const {
      intro,
      name,
      location,
      url,
      className
    } = this.props

    return (
      <div
        className={classnames(
          'fb-statement-card',
          className
        )}
      >
        <div className='fb-statement-card__content'>
          {url && (
            <a className='fb-statement-card__link' href={url}>
              <span className='fb-statement-card__link-text'>Read full story</span>
              <Arrow direction='right' />
            </a>
          )}
          <div className='fb-statement-card__intro'>{intro.quote}</div>
          <div className='fb-statement-card__name'>{name}</div>
          <div className='fb-statement-card__location'>{location}</div>
        </div>
      </div>
    )
  }
}

StatementCard.propTypes = {
  /**
   * Name of the statement giver.
   */
  name: PropTypes.string
}

StatementCard.defaultProps = {
}

export default StatementCard
