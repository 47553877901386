import React from 'react'

import Page from '../Page'
import ImageTextBlock from '../../ImageTextBlock'

const ImageTextPanel = ({
  id,
  panelColor,
  panelTexture,
  panelLabel,
  panelLabelColor,
  isConnectedPanel,
  ...blockProps
}) => {
  return (
    <Page.Panel noPadding color={panelColor} texture={panelTexture} key={`panel${id}`}>
      {panelLabel && (
        <Page.Panel.Label color={panelLabelColor}>
          {panelLabel}
        </Page.Panel.Label>
      )}
      <ImageTextBlock {...blockProps} onColor={panelColor} isConnectedPanel={isConnectedPanel} />
    </Page.Panel>
  )
}

export default ImageTextPanel
