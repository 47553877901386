import React from 'react'
import PropTypes from 'prop-types'

import Card from './Card'
import styles from './downloadInstructions.module.scss'

const DownloadInstructions = ({items}) => {
  return (
    <div className={styles.downloadInstructions}>
      {items.map(({ node }) => {
        return (
          <div key={node.slug} className={styles.downloadInstructions__entry}>
            <Card
              spread
            >
              <Card.Content
                headline={node.title}
                imageUrl={`/assets/images/instructions/${node.slug}.png`}
                imageFullWidth={false}
              >
                {(node.instruction && node.instruction.childMarkdownRemark) && (
                  <div
                    dangerouslySetInnerHTML={{ __html: node.instruction.childMarkdownRemark.html }}
                  />
                )}
              </Card.Content>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

DownloadInstructions.defaultProps = {
  items: []
}

DownloadInstructions.propTypes = {
  /**
   * The items to be displayed.
   */
  items: PropTypes.array
}

export default DownloadInstructions
