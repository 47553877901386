import React, { createContext, Fragment } from 'react'
import get from 'lodash/get'

import TextInput from '../TextInput'
import RadioGroup from '../RadioGroup'
import SelectInput from '../SelectInput'
import _cloneDeep from 'lodash/cloneDeep'
import {COUNTRIES_STATES as states} from '../../utilities/constants'
import MapForm from '../MapForm/MapForm'

const FormContext = createContext({})

const serialize = formData => {
  const data = _cloneDeep(formData)
  for (const prop in data) {
    if (data[prop].value) {
      data[prop] = typeof (data[prop]) === 'string'
        ? data[prop].value.trim()
        : data[prop].value
    } else {
      data[prop] = null
    }
  }
  return data
}

class SignupForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      inputs: props.inputs,
      form: {},
      gmapsCode: null
    }
  }

  componentDidMount () {
    const { inputs } = this.state
    this.updateFormInputs(inputs)
  }

  updateFormInputs (inputs) {
    const { form } = this.state
    /* eslint-disable-next-line no-unused-vars */
    for (const [_, input] of Object.values(inputs).entries()) {
      form[input.name] = {
        value: input.defaultValue,
        hasError: false
      }
    }
    this.setState({ form })
  }

  updateForm = data => {
    const { form, inputs } = this.state
    const { onFormUpdate } = this.props
    const formData = {
      ...form,
      ...data
    }

    if (data.country) {
      const countryIndex = inputs.findIndex(i => i.name === 'country')
      const gmapsCode = inputs[countryIndex].values.find(country => country.value === data.country.value).gmapsCode
      if (gmapsCode) {
        this.setState({gmapsCode})
      }
      const statesIndex = inputs.findIndex(i => i.name === 'state')
      inputs[statesIndex].values = states[data.country.value].sort().map(val => {
        return {
          label: val,
          value: val
        }
      })
      inputs[statesIndex].values.push({label: 'Other', value: 'Other'})
      formData.state.value = null
    }

    this.setState({
      form: formData,
      inputs
    })

    if (onFormUpdate) {
      onFormUpdate(serialize(formData))
    }
  }

  validate () {
    const { inputs, form } = this.state
    /* eslint-disable-next-line no-unused-vars */
    for (const [_, input] of Object.values(inputs).entries()) {
      if (!form[input.name]) {
        form[input.name] = {
          value: '',
          hasError: true
        }
      }

      if (
        form[input.name].value &&
        input.hasChildren &&
        input.children.some(child => !form[child].value)
      ) {
        form[input.name].hasError = true
      }

      if (input.required && !form[input.name].value) {
        form[input.name].hasError = true
      }
    }

    if (get(form, 'emailConfirmation.value', '').trim() !== get(form, 'email.value', '').trim()) {
      form['emailConfirmation'].hasError = true
    }
    this.setState({ form })
    return !Object.keys(form).some(prop => form[prop].hasError)
  }

  getFields () {
    const { form } = this.state
    return serialize(form)
  }

  render () {
    const { inputs, form, gmapsCode } = this.state
    const { children } = this.props
    return (
      <FormContext.Provider>
        <Fragment>
          {
            inputs.map((input, key) => {
              const error = ((form[input.name]?.hasError) && !input.readOnly) || false
              switch (input.type) {
                case 'radio':
                  return (
                    <div className={'fb-input__wrapper'}>
                      <RadioGroup
                        label={input.label}
                        legend={input.label}
                        name={input.name}
                        onValueChange={this.updateForm}
                        error={error}
                        options={input.values}
                        withUppercase={false}
                      />
                      {error ? React.createElement('span', { className: 'fb-input__error-text' }, input.errorMessage) : null}
                    </div>
                  )
                case 'select':
                  return (
                    <div className={'fb-input__wrapper'}>
                      <label className={'fb-input__label'}>
                        {input.label}
                      </label>
                      <SelectInput
                        defaultValue={form[input.name]?.value}
                        options={input.values}
                        name={input.name}
                        hasError={error}
                        onValueChange={this.updateForm}
                      />
                      {error ? React.createElement('span', { className: 'fb-input__error-text' }, input.errorMessage) : null}
                    </div>
                  )
                case 'map':
                  return (
                    <div className={'fb-input__wrapper'}>
                      <MapForm
                        label={input.label}
                        name={input.name}
                        errorMessage={input.errorMessage}
                        required={input.required}
                        sublabel={input.sublabel || false}
                        updateForm={this.updateForm}
                        error={form[input.name]?.hasError || false}
                        country={gmapsCode}
                      />
                    </div>
                  )
                default:
                  return (
                    <TextInput
                      key={key}
                      error={(form[input.name]?.hasError && !input.readOnly) || false}
                      onlyNumber={input.onlyNumber}
                      email={input.isEmail}
                      inputLabelLeft={input.inputLabelLeft ? this.props.currency : false}
                      inputLabelRight={input.inputLabelRight || false}
                      label={input.label}
                      name={input.name}
                      errorMessage={input.errorMessage}
                      required={input.required}
                      value={form[input.name]?.value}
                      toolTip={input.toolTip || false}
                      sublabel={input.sublabel || false}
                      readOnly={input.readOnly}
                      onValueChange={this.updateForm}
                    />
                  )
              }
            })
          }
          {children}
        </Fragment>
      </FormContext.Provider>
    )
  }
}

export default SignupForm
