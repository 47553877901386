const { select } = require('d3-selection')

class D3Chart {
  constructor (props) {
    this.props = props
  }

  init () {
    const { element, data } = this.props
    const range = data.reduce((acc, val) => {
      const values = Object.values(val).slice(1)
      acc.push(...values)
      return acc
    }, [])

    if (!element) {
      throw Error('Cant render bar chart without element')
    }
    const resized = this.onResize()
    this.drawChart({
      range,
      ...resized
    })

    let resizeTimer = null
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        const resized = this.onResize()
        const s = select(element).selectAll('svg')
        s.remove()
        this.drawChart({
          range,
          ...resized
        })
      }, 100)
    })
  }

  drawChart () {

  }

  onResize () {
    let { width, height, mobileWidth = 0, mobileHeight = 0 } = this.props
    let fontSize = '13px'
    const currentWidth = window.innerWidth

    if (currentWidth <= 500 && (mobileWidth && mobileHeight)) {
      width = mobileWidth
      height = mobileHeight
      fontSize = '9px'
    }
    return { width, height, fontSize }
  }

  // fixme: word wraps solution grabbed from d3 community need think better option
  wrap (text, width) {
    // fixme: hacky solution for negative values quick fix
    let multiply = 1
    if (this && this.multiply) {
      multiply = this.multiply
    }
    text.each(function () {
      const text = select(this)
      const words = text.text().split(/\s+/).reverse()
      let word
      let line = []
      let lineNumber = 0
      const lineHeight = 1.1 // ems
      const y = text.attr('y')
      const dy = parseFloat(text.attr('dy') || 0)
      let tspan = text.text(null).append('tspan').attr('y', y).attr('dy', dy + 'em')
      // eslint-disable-next-line no-cond-assign
      while (word = words.pop()) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text.append('tspan')
            .attr('x', 0)
            .attr('y', y * multiply)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word)
        }
      }
    })
  }
}

module.exports = D3Chart
