const {
  cashSales,
  map,
  stockWriteOffsForShelfLife,
  netIncome
} = require('./formulas')
const _pick = require('lodash/pick')

const months = require('../common/months')
const returnProps = [
  'cashSales',
  'income',
  'costOfSales',
  'costOfStockSold',
  'expenses',
  'payroll',
  'logistics',
  'marketing',
  'debtService',
  'stockWriteOffs',
  'compliance',
  'rent',
  'slMemberFee',
  'netIncome'
]

module.exports = class ProfitAndLoss {
  constructor (props) {
    this.inputs = props.base
    this.startInputs = props.startInputs
    this.supplyAndDemand = props.supplyAndDemand
    this.cashSales = []
    this.income = []
    this.costOfStockSold = this.supplyAndDemand.costOfStockSold
    this.costOfSales = this.costOfStockSold
    this.expenses = []
    this.payroll = []
    this.logistics = []
    this.marketing = []
    this.debtService = []
    this.stockWriteOffs = []
    this.compliance = []
    this.rent = []
    this.slMemberFee = []
    this.netIncome = []
  }

  calculateStockWriteOffs () {
    this.stockWriteOffs = this.supplyAndDemand.writeOffs
  }

  calculateStockWriteOffsForShelfLife (stock) {
    this.stockWriteOffs = stockWriteOffsForShelfLife({
      stock,
      purchases: this.supplyAndDemand.purchases,
      costOfStockSold: this.costOfStockSold,
      writeOffs: this.inputs.operations.writeOffs
    })
  }

  calculateNetIncome () {
    // console.log(this.income, this.costOfSales, this.expanses);
    this.netIncome = netIncome({
      income: this.income,
      costOfSales: this.costOfSales,
      expenses: this.expenses
    })
  }

  calculateExpenses () {
    this.expenses = months.map((_, key) => Math.round((
      this.payroll[key + 1] +
      this.logistics[key + 1] +
      this.marketing[key + 1] +
      this.debtService[key + 1] +
      this.compliance[key + 1] +
      this.rent[key + 1] +
      this.slMemberFee[key + 1] +
      this.stockWriteOffs[key + 1]
    )))
    this.expenses.unshift(0)
  }

  calculateCashSalesAndIncome () {
    const arr = []
    for (const costOfStockGood of this.supplyAndDemand.costOfStockSold) {
      arr.push(cashSales({
        costOfStockGood,
        retailMarkup: this.inputs.financing.retailMarkup
      }))
    }
    this.cashSales = arr

    this.income = arr
  }

  calculateElse () {
    this.costOfSales = this.costOfStockSold

    this.payroll = map(this.inputs.runningCosts.labor)

    this.logistics = map(this.inputs.operations.logistics)

    this.marketing = map(this.inputs.runningCosts.marketing)

    this.debtService = map(this.inputs.financing.debtServicing)

    this.compliance = map(Math.round((this.inputs.runningCosts.compliance / 12)))

    this.rent = map(Math.round(this.inputs.runningCosts.rent / 12))

    this.slMemberFee = map(this.inputs.financing.sLMemberFee)
  }

  result () {
    return _pick(this, returnProps)
  }
}
