import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CardContent from './CardContent'

const Card = (props) => {
  const {
    children,
    component,
    className,
    spread,
    tight,
    invert,
    ...otherProps
  } = props

  const commonProps = {
    className: classnames(
      className,
      'fb-card',
      {'fb-card--spread': spread},
      {'fb-card--tight': tight},
      {'fb-card--invert': invert},
      className
    ),
    invert,
    ...otherProps
  }

  let wrapperComponent = component
  let specificProps = {}

  // if a wrapper hasn't been set yet, we assume it's a regular div
  if (!wrapperComponent) {
    wrapperComponent = 'div'
  }

  return React.createElement(
    wrapperComponent,
    {
      ...commonProps,
      ...specificProps
    },
    React.Children.map(children, child => (
      React.cloneElement(child, {
        invert
      })
    ))
  )
}

Card.propTypes = {
  /**
   * Content of the card.
   * You can use the associated sub-component (CardContent)
   * or any other valid HTML content
   */
  children: PropTypes.node.isRequired,
  /**
   * Pass an optional alternative top level element to use as the card, e.g. CrossLink.
   * Can be a Component, stateless function, or string corresponding to a default JSX element.
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string,

  /**
   * Makes the card spread to take up all available space
   * Works well for list in flex containers,
   * to make cards even size regardless of content
   */
  spread: PropTypes.bool,

  /**
   * Makes the card padding smaller.
   * Works well for containers with limited space.
   */
  tight: PropTypes.bool,

  /**
   * Renders a dark version of the card, e.g. for highlighted job openenings
   */
  invert: PropTypes.bool
}

Card.defaultProps = {
  component: undefined,
  className: '',
  spread: false,
  tight: false,
  invert: false
}

Card.Content = CardContent

export default Card

export { CardContent }
