import React from 'react'

class CalcResultsExplanation extends React.PureComponent {
  renderCopy = (country, tier) => {
    if (country === 'nigeria') {
      switch (tier) {
        case 'tier1':
          return (
            <p>
              Up and coming? Shelf Life can help you expand the range of products you offer and miss fewer sales, avoid expensive loans, and end your first year with <strong>up to 20% more cash on hand.</strong>
            </p>
          )
        case 'tier2':
          return (
            <p>
              Stores yours size can realize up to <strong>100,000 or more each year</strong> from joining Shelf Life and <strong>around 20% more cash on hand.</strong>
            </p>
          )
        case 'tier3':
          return (
            <p>
              Stores your size can realize around <strong>25% more cash on hand</strong> and realize <strong>up to 300,000 in annual net gains.</strong>
            </p>
          )
        case 'tier4':
          return (
            <p>
              Stores yours size can realize <strong>an extra 750,000 or more</strong> each year from joining Shelf Life and <strong>33% more cash on hand.</strong>
            </p>
          )
        case 'tier5':
          return (
            <p>
              Big pharmacies get bigger with Shelf Life. Stores yours size can realize <strong>an extra 1,000,000 or more</strong> each year from joining Shelf Life and <strong>34% more cash on hand.</strong>
            </p>
          )
        default:
          return ''
      }
    }

    if (country === 'kenya') {
      switch (tier) {
        case 'tier1':
          return (
            <p>
              Up and coming? Shelf Life can help you expand the range of products you offer and miss fewer sales, avoid expensive loans, and end your first year with <strong>up to 30% more cash on hand.</strong>
            </p>
          )
        case 'tier2':
          return (
            <p>
              Stores yours size can realize <strong>over 100,000 or more each year</strong> from joining Shelf Life and <strong>around 30-40% more cash on hand.</strong>
            </p>
          )
        case 'tier3':
          return (
            <p>
              Stores your size can realize around <strong>40-45% more cash on hand</strong> and realize <strong>over 250,000 annual net gains.</strong>
            </p>
          )
        case 'tier4':
          return (
            <p>
              Stores yours size can realize <strong>an extra 300,000 or more</strong> each year from joining Shelf Life and <strong>48% more cash on hand.</strong>
            </p>
          )
        case 'tier5':
          return (
            <p>
              Big pharmacies get bigger with Shelf Life. Stores yours size can realize <strong>an extra 400,000 or more</strong> each year from joining Shelf Life and <strong>50% more cash on hand.</strong>
            </p>
          )
        default:
          return ''
      }
    }

    return ''
  }

  render () {
    const { country, tier } = this.props

    if (!country || !tier) {
      return
    }

    return (
      <div className='fb-calculation-result__explanation'>
        {this.renderCopy(country, tier)}
      </div>
    )
  }
}

export default CalcResultsExplanation
