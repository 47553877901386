import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import BoxHeader from './BoxHeader'
import BoxBody from './BoxBody'
import BoxFooter from './BoxFooter'

const Box = (props) => {
  const {
    children,
    component,
    className,
    layout,
    tight,
    narrow,
    rounded,
    background,
    fullOnMobile,
    ...otherProps
  } = props

  const commonProps = {
    className: classnames(
      className,
      'fb-box',
      {'fb-box--grid': layout === 'grid'},
      {'fb-box--grid-spaced': layout === 'grid-spaced'},
      {'fb-box--block': layout === 'block'},
      {'fb-box--tight': tight},
      {'fb-box--narrow': narrow},
      {'fb-box--full-width-mobile': fullOnMobile},
      {'fb-box--rounded': rounded},
      {'fb-box--bg-white': background === 'white'}
    ),
    ...otherProps
  }

  let wrapperComponent = component
  const specificProps = {}

  // if a wrapper hasn't been set yet, we assume it's a regular div
  if (!wrapperComponent) {
    wrapperComponent = 'div'
  }

  return React.createElement(
    wrapperComponent,
    {
      ...commonProps,
      ...specificProps
    },
    React.Children.map(children, child => (
      child && React.cloneElement(child, {
      })
    ))
  )
}

Box.propTypes = {
  /**
   * Content of the card.
   * You can use the associated sub-component (CardContent)
   * or any other valid HTML content
   */
  children: PropTypes.node.isRequired,

  /**
   * Optionally add an additional class name
   */
  className: PropTypes.string,

  /**
   * Choose layout type
   */
  layout: PropTypes.string,

  /**
   * Optionally add a background
   */
  background: PropTypes.string,

  /**
   * Make box less spaced
   */
  tight: PropTypes.bool,

  /**
   * Make box more narrow with bigger padding
   */
  narrow: PropTypes.bool,

  /**
   * Make box have no padding on small screen
   */
  fullOnMobile: PropTypes.bool,

  /**
   * Make box corners rounded
   */
  rounded: PropTypes.bool
}

Box.defaultProps = {
  component: undefined,
  className: '',
  tight: false,
  narrow: false,
  rounded: false,
  fullOnMobile: false,
  background: ''
}

Box.Header = BoxHeader
Box.Body = BoxBody
Box.Footer = BoxFooter

export default Box

export { BoxHeader }
