import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

const HeroCta = ({
  children,
  url,
  buttonText,
  color,
  ...otherProps
}) => (
  <div className='fb-hero-cta'>
    {children && (
      <div className='fb-hero-cta__intro'>
        {children}
      </div>
    )}
    <Button cta color={color} href={url} {...otherProps}>
      {buttonText}
    </Button>
  </div>
)

HeroCta.propTypes = {
  /**
   * CTA button url.
   */
  url: PropTypes.string,

  /**
   * Text for the CTA button.
   */
  buttonText: PropTypes.string.isRequired,

  /**
   * Color code for the CTA button
   */
  color: PropTypes.oneOf(['brand', 'accent'])
}

HeroCta.defaultProps = {
  color: 'accent'
}

export default HeroCta
