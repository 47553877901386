import React from 'react'
import Button from './Button'
import Logo from './Logo'

import styles from './productIntro.module.scss'

const ProductBlock = ({target, intro, logoCode}) => (
  <div className={styles.productBlock}>
    <div style={{ height: logoCode === 'fs' ? '48px' : '64px', marginBottom: '24px' }}>
      <Logo siteCode={logoCode} className={styles.productBlock__logo} />
    </div>
    <div className={styles.productBlock__intro}>
      {intro}
    </div>
    <Button
      href={target}
      color='accent'
      className={styles.productBlock__button}
    >
      Go to site
    </Button>
  </div>
)

const productIntro = () => {
  return (
    <div className={styles.productIntro}>
      <div className={`theme-sl ${styles.productIntro__product}`}>
        <ProductBlock
          target='https://shelflife.co/'
          intro='Supply service ensuring availability, reducing costs, and empowering pharmacies to grow by improving access'
          logoCode='sltag'
        />
      </div>
      <div className={`theme-fs ${styles.productIntro__product}`}>
        <ProductBlock
          target='https://field.supply'
          intro='Powerful, intuitive, offline-capable supply chain management software and analytics'
          logoCode='fs'
        />
      </div>
    </div>
  )
}

export default productIntro
