import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Logo = ({
  file,
  title,
  id,
  href
}) => {
  let component = 'span'
  let componentProps = {}
  let isLandscape = false

  const {
    url,
    details
  } = file

  const {
    image
  } = details

  if (!image) return null

  const widthRatioPercentage = Math.round((image.height / image.width) * 100)
  const heightRatioPercentage = Math.round((image.width / image.height) * 100)

  if (image && (widthRatioPercentage < 65)) {
    isLandscape = true
  }

  if (href) {
    component = 'a'
    componentProps = {
      href,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  }

  return createElement(
    component,
    {
      className: classnames(
        'fb-logo-block__item',
        {'fb-logo-block__item--not-landscape': !isLandscape},
        {'fb-logo-block__item--landscape': isLandscape}
      ),
      ...componentProps
    },
    (
      <div
        className='fb-logo-block__size-wrapper'
        style={{
          // Landscape are limited on width and should be max 200px
          // Non-landscape images should have a max height of 100px
          // which we control through width, since height is not reliable
          width: isLandscape ? '200px' : `${heightRatioPercentage}px`
        }}
      >
        <div
          className='fb-logo-block__ratio-wrapper'
          style={{
            paddingBottom: `${widthRatioPercentage}%`
          }}
        >
          <img
            className='fb-logo-block__image'
            height={isLandscape ? '' : '100px'}
            width={isLandscape ? '200px' : ''}
            src={url}
            alt={title}
            title={title}
          />
        </div>
      </div>
    )
  )
}

const LogoBlock = ({logos}) => (
  <div className='fb-logo-block'>
    {logos.map(logo => (
      <Logo
        key={logo.id}
        file={logo.image.file}
        title={logo.title}
        id={logo.id}
        href={logo.url ? logo.url.url : null}
      />
    ))}
  </div>
)

LogoBlock.propTypes = {
  /**
   * An array of logo objects.
   */
  logos: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default LogoBlock
