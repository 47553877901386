const _random = require('lodash/random')
const _sum = require('lodash/sum')

const months = require('../common/months')

const toPercent = (value) => {
  const val = typeof value === 'string' ? parseFloat(value.replace('%', '')) : value
  return val / 100
}

const changesIn = (list) => {
  const arr = []
  for (const [i, val] of list.entries()) {
    arr.push(Math.abs(val - (list[i - 1] || 0)))
  }
  return arr
}

const startingCash = (avgMonthSales) => (avgMonthSales * 0.5) * 1.33

exports.startingCash = startingCash

exports.demand = (props) => {
  const { salesPotential, demandVariance, retailMarkup } = props
  const { min, max } = demandVariance
  const rand = toPercent(_random(min, max))
  return rand * (salesPotential / (1 + toPercent(retailMarkup)))
}

exports.supply = (props) => {
  const { salesPotential, inventoryAccuracy, retailMarkup, closingStock } = props
  const { min, max } = inventoryAccuracy
  const rand = toPercent(_random(min, max))
  return (rand * (salesPotential / (1 + toPercent(retailMarkup)))) - closingStock
}

exports.purchases = (supply) => supply > 0 ? supply : 0

exports.openingStock = (props) => props.purchases + props.closingStock

exports.costOfStockSold = (props) => {
  const { openingStock, demand } = props
  return openingStock < demand ? openingStock : demand
}

exports.closingStock = (props) => {
  const { openingStock, costOfStockSold } = props
  return openingStock - costOfStockSold
}

exports.closingStockForShelfLife = (props) => {
  const { openingStock, costOfStockSold, writeOffs } = props
  return ['December', ...months].map((_, key) => openingStock[key] - writeOffs[key] - costOfStockSold[key])
}

exports.writeOffs = (props) => {
  const { purchases, writeOff } = props
  return toPercent(writeOff) * (_sum(purchases) / 12)
}

exports.cashSales = (props) => {
  const { costOfStockGood, retailMarkup } = props
  return costOfStockGood * (1 + toPercent(retailMarkup))
}

exports.map = (input, firstMonth = 0) => {
  const arr = months.map((_) => input)
  arr.unshift(firstMonth)
  return arr
}

exports.accountPayable = (props) => {
  const { purchases } = props
  const tradeCredit = toPercent(props.tradeCredit)
  return purchases.map((val) => val * tradeCredit)
}

exports.accountPayableForShelfLife = (props) => {
  const { costOfStockSold } = props
  return costOfStockSold.map((val) => val * 0.5)
}

exports.prePaidRent = (rent) => {
  const prePaidRent = [rent]
  for (const [i] of months.entries()) {
    const value = prePaidRent[i] - (prePaidRent[0] / 12)
    prePaidRent.push(Math.abs(value))
  }
  return prePaidRent.map(v => v)
  // return prePaidRent.map(v => Math.round(v))
}

exports.netMargin = (props) => {
  const { income, costOfSales, expenses } = props
  const arr = [0]
  for (let i = 0; i < months.length; i++) {
    const value = (income[i + 1] - (costOfSales[i + 1] + expenses[i + 1])) / income[i + 1]
    arr.push(value * 100)
  }
  return arr
}

exports.missedSales = (props) => {
  const { demand, costOfStockSold, retailMarkup } = props
  const arr = [0]
  for (let i = 0; i < months.length; i++) {
    const value = demand[i + 1] > costOfStockSold[i + 1] ? (demand[i + 1] - costOfStockSold[i + 1]) * (1 + toPercent(retailMarkup)) : 0
    arr.push(value)
  }
  return arr
}

exports.netWorkingCapital = (props) => {
  const { assets, accountPayable, debt } = props
  const arr = [0]
  for (let i = 0; i < months.length; i++) {
    const value = assets[i + 1] - (debt[i + 1] + accountPayable[+1])
    // arr.push(Math.round(value * 100))
    arr.push(value)
  }
  return arr
}

exports.changesInRentPayment = (props) => {
  const { rent, prePaidRent } = props
  const changeInRentPayment = [rent]
  for (const [i] of months.entries()) {
    const index = months[i + 1] ? i + 1 : null
    const value = (prePaidRent[index] ? prePaidRent[index] : 0) - prePaidRent[i]
    const val = Math.abs(value)
    changeInRentPayment.push(val)
  }
  return changeInRentPayment
}

exports.changeInStock = (stock) => changesIn(stock)

exports.changeInAccountPayable = (accountPayable) => changesIn(accountPayable)

exports.totalCash = (props) => {
  const {
    changeInCompliancePrepayment,
    changeInRentPayment,
    changeInStock,
    avgMonthSales,
    cashFromFinancing,
    changeInAccountPayable,
    netIncome
  } = props

  const sum = changeInCompliancePrepayment[0] + changeInRentPayment[0] + changeInStock[0]
  const firstMonthTotalCash = (startingCash(avgMonthSales) + cashFromFinancing[0]) - (sum + changeInAccountPayable[0])
  const totalCash = [firstMonthTotalCash]

  for (let i = 0; i < months.length; i++) {
    const left = (totalCash[i] + netIncome[i + 1] + changeInAccountPayable[i + 1])
    const right = ((-changeInCompliancePrepayment[i + 1] + -changeInRentPayment[i + 1]) + changeInStock[i + 1])
    totalCash.push(left - right)
  }
  return totalCash
}

exports.assets = (props) => {
  const { stock, prePaidCompliance, prePaidRent, cashOnHand } = props
  return ['December', ...months].map((_, key) => stock[key] + prePaidCompliance[key] + prePaidRent[key] + cashOnHand[key])
}

exports.debt = (props) => {
  const { cumulative, debtService } = props
  const arr = [cumulative]
  for (let i = 0; i < months.length; i++) {
    arr.push(arr[i] + debtService[i + 1])
  }
  return arr
}

exports.equity = (props) => {
  const { assets, debt, accountPayable } = props
  return ['December', ...months].map((_, key) => assets[key] - (debt[key] + accountPayable[key]))
}

exports.liabilities = (props) => {
  const { equity, debt, accountPayable } = props
  return ['December', ...months].map((_, key) => equity[key] + debt[key] + accountPayable[key])
}

exports.stockWriteOffsForShelfLife = (props) => {
  const { stock, purchases, costOfStockSold, writeOffs } = props
  const stockWriteOffs = months.map((val, key) => {
    const s = stock[key - 1] ? stock[key - 1] : 0
    return (s + purchases[key] + costOfStockSold[key]) * toPercent(writeOffs)
  })
  stockWriteOffs.unshift(0)
  return stockWriteOffs
}

exports.netIncome = (props) => {
  const { income, costOfSales, expenses } = props
  const netIncome = []
  for (let i = 0; i < months.length; i++) {
    netIncome.push(
      income[i + 1] - (costOfSales[i + 1] + expenses[i + 1])
    )
  }

  netIncome.unshift(0)
  return netIncome
}

exports.retailMarkupForShelfLife = (props) => {
  const { salesPotential, retailMarkup, slPrice } = props
  return (salesPotential / ((salesPotential / (1 + toPercent(retailMarkup))) * (1 + toPercent(slPrice))) - 1) * 100
}

exports.roundAll = (data) => {
  for (const prop of Object.keys(data)) {
    for (const subProp of Object.keys(data[prop])) {
      data[prop][subProp] = data[prop][subProp].map(v => Math.round(v))
    }
  }
  return data
}
