import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Img from 'gatsby-image'

import Quote from '../../icons/Quote'

class SuccessStoriesHero extends React.Component {
  render () {
    const {
      title,
      subtitle,
      pharmacyName,
      image,
      name,
      quote,
      url,
      className
    } = this.props

    return (
      <div
        className={classnames(
          'fb-success-stories-hero',
          className
        )}
      >
        <div className='fb-success-stories-hero__outer'>
          <div className='fb-success-stories-hero__wrapper fb-success-stories-hero__wrapper--fix-layout'>
            <h1 className='fb-success-stories-hero__title'>{title}</h1>
            <h3 className='fb-success-stories-hero__subtitle'>
              {subtitle}
            </h3>
          </div>
          <div className='fb-success-stories-hero__content-wrapper'>
            <div className='fb-success-stories-hero__image-wrapper' ref={node => (this.imageContainer = node)}>
              <Img className='fb-success-stories-hero__image' alt={name} fluid={image.fluid} imgStyle={{objectFit: `contain`, objectPosition: `center bottom`}} />
            </div>
            <div className='fb-success-stories-hero__content'>
              <div className='fb-success-stories-hero__name'>{name}</div>
              <div className='fb-success-stories-hero__location'>{pharmacyName}</div>
              <div className='fb-success-stories-hero__intro'>
                <Quote className='fb-success-stories-hero__icon' />
                {quote}
              </div>
              {url && (
                <a className='fb-success-stories-hero__lead' href={url}>Read full story</a>
              )}
            </div>
          </div>
        </div>

      </div>
    )
  }
}

SuccessStoriesHero.propTypes = {
  /**
   * Name of the statement giver.
   */
  name: PropTypes.string
}

SuccessStoriesHero.defaultProps = {
}

export default SuccessStoriesHero
