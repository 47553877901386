export const COUNTRY_CURRENCY_SYMBOL = {
  nigeria: '₦',
  kenya: 'KSh'
}

export const CALCULATION_DATA = {
  nigeria: {
    tier1: {
      sales: 'Below 500k',
      extraEarnings: '48,631',
      cashOnHand: '18.1'
    },
    tier2: {
      sales: '500k to 1M',
      extraEarnings: '98,644',
      cashOnHand: '21.3'
    },
    tier3: {
      sales: '1M to 1.7M',
      extraEarnings: '293,057',
      cashOnHand: '26.3'
    },
    tier4: {
      sales: '1.7M to 3M',
      extraEarnings: '747,014',
      cashOnHand: '33.8'
    },
    tier5: {
      sales: 'Over 3M',
      extraEarnings: '1,482,575',
      cashOnHand: '34.4'
    }
  },
  kenya: {
    tier1: {
      sales: 'Below 250k',
      extraEarnings: '73,342',
      cashOnHand: '29.5'
    },
    tier2: {
      sales: '250k to 350k',
      extraEarnings: '115,633',
      cashOnHand: '35.8'
    },
    tier3: {
      sales: '350k to 500k',
      extraEarnings: '256,825',
      cashOnHand: '46.1'
    },
    tier4: {
      sales: '500k to 700k',
      extraEarnings: '351,642',
      cashOnHand: '48.5'
    },
    tier5: {
      sales: 'Over 700k',
      extraEarnings: '402,401',
      cashOnHand: '52.0'
    }
  }
}
