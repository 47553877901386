import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../Text'

const BoxHeader = ({
  children,
  title,
  icon,
  center,
  className,
  color
}) => {
  const classNames = classnames(
    'fb-box__header',
    {'fb-box__header--center': center},
    className
  )

  const titleClassNames = classnames(
    'fb-box__header--title',
    {'fb-box__header--title--purple': color === 'purple'}
  )

  return (
    <div className={classNames}>
      {title && <Text.Heading size='large' className={titleClassNames}>{icon || null} {title}</Text.Heading>}
      {children || null}
    </div>
  )
}

BoxHeader.propTypes = {
  /**
   * Card body
   */
  children: PropTypes.node,
  /**
   * Optional title of box
   */
  title: PropTypes.string
}

BoxHeader.displayName = 'Box.Header'

export default BoxHeader
