import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const BoxFooter = ({
  children,
  isColumn
}) => (
  <div className={classNames(
    'fb-box__footer',
    {'fb-box__footer--column': isColumn}
  )}>
    {children}
  </div>
)

BoxFooter.propTypes = {
  /**
   * Box Footer
   */
  children: PropTypes.node.isRequired
}

BoxFooter.displayName = 'Box.Footer'

export default BoxFooter
