import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Img from 'gatsby-image'

import Bubble from '../../icons/Bubble'

class StatementAvatar extends React.Component {
  render () {
    const {
      image,
      className,
      isExpanded,
      onClick
    } = this.props

    return (
      <div
        className={classnames(
          'fb-statement-avatar',
          {'fb-statement-avatar--active': isExpanded},
          className
        )}
      >
        {image && (
          <div className='fb-statement-avatar__image-wrapper' onClick={onClick} ref={node => (this.imageContainer = node)}>
            <Img className='fb-statement-avatar__image' fluid={image.fluid} />
            <div className='fb-statement-avatar__icon-container'>
              <div className='fb-statement-avatar__icon-animation' />
              <Bubble className='fb-statement-avatar__icon' />
            </div>
          </div>
        )}
      </div>
    )
  }
}

StatementAvatar.propTypes = {
  /**
   * Background image, must have gatsby image fluid object.
   */
  image: PropTypes.object,
  /**
   * ...
   */
  isExpanded: PropTypes.bool,
  /**
   * ...
   */
  onClick: PropTypes.func
}

StatementAvatar.defaultProps = {
  isExpanded: false
}

export default StatementAvatar
