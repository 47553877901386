export const median = values => {
  values.sort((a, b) => a - b)
  const half = Math.floor(values.length / 2)
  const result = values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2.0
  return Math.round(result)
}

export const prepareTierOptions = (countryData) => {
  let options = []

  for (const tier in countryData) {
    const option = {
      label: countryData[tier].sales,
      value: tier
    }
    options = [...options, option]
  }

  return options
}

export const prepareCountryOptions = (countries) => {
  let options = []

  countries.map((country) => {
    const option = {
      label: country,
      value: country
    }
    options = [...options, option]
  })

  return options
}
