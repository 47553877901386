const months = require('../common/months')

exports.totalCashCart = (data) => {
  const { baseLine, shelfLife } = data
  const arr = []
  for (const [index, month] of months.entries()) {
    arr.push({
      group: month,
      baseLine: baseLine[index + 1],
      shelfLife: shelfLife[index + 1]
    })
  }
  return arr
}

exports.annualReportChart = (data) => {
  const { equityValue, netProfit, netWorkingCapital } = data
  return [
    {
      group: 'Equity Value',
      baseLine: equityValue.baseLine,
      shelfLife: equityValue.shelfLife
    },
    {
      group: 'Net Profit',
      baseLine: netProfit.baseLine,
      shelfLife: netProfit.shelfLife
    },
    {
      group: 'Net Working Capital',
      baseLine: netWorkingCapital.baseLine,
      shelfLife: netWorkingCapital.shelfLife
    }
  ]
}

exports.savingChart = (data) => {
  const { missedSales, totalWriteOffs } = data
  return [
    {
      group: 'Total missed sales',
      baseLine: missedSales.baseLine,
      shelfLife: missedSales.shelfLife
    },
    {
      group: 'Total Write-offs',
      baseLine: totalWriteOffs.baseLine,
      shelfLife: totalWriteOffs.shelfLife
    }
  ]
}
