import React from 'react'
import get from 'lodash/get'

import Box from '../../Box'
import ProgressSteps from '../../ProgressSteps'
import Text from '../../Text'

const progressSteps = {
  step1: {
    description: 'Create a free Shelf Life account',
    infoPending: 'Set a password to activate your account'
  },
  step2: {
    description: 'Complete your membership application',
    infoPending: 'Your application is being checked',
    infoCompleted: 'Your application was approved!'
  },
  step3: {
    description: 'Sign contract and subscribe to products'
  }
}

class SignupConfirmation extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      email: ''
    }
  }

  componentDidMount () {
    const email = get(this.props, 'location.state.email', null)

    this.setState({ email: email })
  }

  render () {
    const { email } = this.state

    return (
      <Box
        className='signup-confirmation'
        narrow
        fullOnMobile
      >
        <Text tag='h2' size='xlarge' weight='bold'>
          Almost there... Check your email to activate your account
        </Text>
        <Box.Body className='signup-confirmation__body'>
          <div
            className='signup-confirmation__mail-prompt'
          >
            <img
              className='signup-confirmation__mail-prompt-pic'
              src='/assets/images/envelope.png'
            />
            <p>
              We've sent an email to <strong>{email}</strong>. Hit the <strong>set password</strong> link in the email and activate your account.
            </p>
          </div>
          <div className='signup-confirmation__progress'>
            <ProgressSteps
              steps={progressSteps}
              activeStep='step1'
              activeStepPending
            />
          </div>
          <Text tag='h3' weight='bold'> Having problems receiving mail?</Text>
          <div>
            <p>
              First, try checking your spam folder. If you haven’t received anything, please reach out to us at any time via our <a className='signup-confirmation__link' href='https://shelflife.co/contact/'>contact page</a> and we’ll help you get set up.
            </p>
          </div>
        </Box.Body>
      </Box>
    )
  }
}

export default SignupConfirmation
