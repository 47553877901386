import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Page from './Page'

import Hero from '../Hero'
import SuccessStoriesHero from '../SuccessStoriesHero'
import SuccessStories from '../SuccessStories'
import TextureHeader from '../TextureHeader'
import LogoBlockPanel from './Panel/LogoBlockPanel'
import LongTextPanel from './Panel/LongTextPanel'
import ShortTextPanel from './Panel/ShortTextPanel'
import ImageTextPanel from './Panel/ImageTextPanel'
import StatsBlockPanel from './Panel/StatsBlockPanel'
import PullQuotePanel from './Panel/PullQuotePanel'

import ProductIntro from '../productIntro'
import PeopleWall from '../peopleWall'
import Posts from '../jobPosts'
import Instructions from '../downloadInstructions'
import Calculator from '../SL/Calculator/CalculatorFormInnerPage'
import CalculatorResult from '../SL/Calculator/CalcResultsSimple'
import CalculatorFormHomePage from '../SL/Calculator/CalculatorFormHomePage'
import SignUp from '../SL/SignUp/index'
import SignupConfirmation from '../SL/SignUp/SignupConfirmation'
import MembershipSelectorCard from '../SL/MembershipSelectorCard/MembershipSelectorCard'

const { siteCode, siteConfig } = require('../../../site-config')
const { careersPage, newsPage } = siteConfig[siteCode]

export const customBlockFragment = graphql`
  fragment CustomBlockFragment on ContentfulPanelCustomBlock {
    codeId
  }
`

export const pageBlocksFragment = graphql`
  fragment PageBlocksFragment on ContentfulPage {
    blocks {
      ...HeroFragment
      ...TextureHeaderFragment
      ...LogoBlockFragment
      ...LongTextBlockFragment
      ...ShortTextBlockFragment
      ...ImageTextBlockFragment
      ...StatsBlockFragment
      ...PullQuoteFragment
      ...CustomBlockFragment
    }
  }
`

const ContentfulPage = (props) => {
  const {
    contentAbove,
    blocks,
    children,
    footerColor,
    siteName,
    pageUrl,
    pageTitle,
    pageDescription,
    featuredHero,
    stories,
    showSuccessStoriesTop,
    showSuccessStoriesBottom,
    membershipCards,
    membershipHeader
  } = props
  // Get the first image block in the page and use that for the share image
  const mainImage = blocks && blocks
    .map(b => b.image)
    .filter(x => x && x.file)
    .map(image => `${image.file.url}?w=1200`)
    .shift()

  let showSuccessStoriesHero = false
  let title, image, name, quote, url, pharmacyName

  if (featuredHero && featuredHero.length) {
    showSuccessStoriesHero = true
    title = featuredHero[0].node.title
    image = featuredHero[0].node.picture
    name = featuredHero[0].node.name
    quote = featuredHero[0].node.quote.quote
    // Current featured hero has no url and gatsby won't allow optional field
    // on single instance. Bring back if there is url here and in pageQuery.
    // url = featuredHero[0].node.url
    pharmacyName = featuredHero[0].node.pharmacyName
  }

  return (
    <Page footerColor={footerColor}>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {pageDescription && <meta name='description' content={pageDescription} />}
        {pageTitle && <meta property='og:title' content={pageTitle} />}
        {pageUrl && <meta property='og:url' content={pageUrl} />}
        {mainImage && <meta property='og:image' content={mainImage || image} />}
        {siteName && <meta property='og:site_name' content={siteName} />}
      </Helmet>
      {contentAbove}
      {blocks && blocks.map((entry, index) => {
        const isLast = index === blocks.length - 1
        const shouldSpread = isLast && !children
        const prevPanel = blocks[index - 1]
        const nextBlockTransparent = entry.nextPanelColor === 'transparent'
        const prevPanelColor = prevPanel && !prevPanel.panelTexture ? prevPanel.panelColor : null
        let isConnectedPanel = prevPanelColor === entry.panelColor

        if (prevPanel && prevPanel.__typename === 'ContentfulPanelLongTextBlock') {
          isConnectedPanel = entry.panelColor === 'grey'
        }

        switch (entry.__typename) {
          case 'ContentfulPanelHero':
            if (showSuccessStoriesHero && entry.codeId === 'slHeroStories') { return }
            return <Hero {...entry} key={entry.id} />
          case 'ContentfulPanelLogoBlock':
            return <LogoBlockPanel {...entry} key={entry.id} spread={shouldSpread} />
          case 'ContentfulPanelLongTextBlock':
            isConnectedPanel = prevPanelColor === 'grey' && !entry.headline
            return <LongTextPanel {...entry} key={entry.id} isConnectedPanel={isConnectedPanel} spread={shouldSpread} nextBlockTransparent={nextBlockTransparent} />
          case 'ContentfulPanelPullQuote':
            return <PullQuotePanel {...entry} key={entry.id} spread={shouldSpread} />
          case 'ContentfulPanelShortTextBlock':
            return <ShortTextPanel {...entry} key={entry.id} spread={shouldSpread} />
          case 'ContentfulPanelImageTextBlock':
            return <ImageTextPanel {...entry} key={entry.id} isConnectedPanel={isConnectedPanel} spread={shouldSpread} />
          case 'ContentfulPanelStatisticsBlock':
            if (showSuccessStoriesHero) { return }
            return <StatsBlockPanel {...entry} key={entry.id} spread={shouldSpread} />
          case 'ContentfulPanelTextureHeader':
            return <TextureHeader {...entry} key={entry.id} />
          case 'ContentfulPanelCustomBlock':
            if (entry.codeId === 'productsIntro') {
              return <ProductIntro key={`${entry.codeId}-${index}`} />
            }
            if (entry.codeId === 'appDownloadInstructions') {
              return (
                <Page.Panel noPadding color='grey' spread={shouldSpread} key={entry.codeId}>
                  <Instructions items={props.instructions} />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'slHeroStories') {
              return (
                <Page.Panel key={entry.codeId} noPadding spread={shouldSpread}>
                  {showSuccessStoriesHero &&
                    <SuccessStoriesHero
                      title={title}
                      pharmacyName={pharmacyName}
                      name={name}
                      image={image}
                      quote={quote}
                      url={url}
                      {...featuredHero}
                    />}
                  {stories && showSuccessStoriesTop && <SuccessStories stories={stories} />}
                </Page.Panel>
              )
            }
            if (entry.codeId === 'peopleWall') {
              return (
                <Page.Panel noPadding color='grey' spread={shouldSpread} key={entry.codeId}>
                  <PeopleWall people={props.people} />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'jobPosts') {
              return (
                <Page.Panel noPadding color='grey' spread={shouldSpread} key={entry.codeId}>
                  <Posts items={props.jobs} basePath={careersPage.path} labelKey='location' />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'newsPosts') {
              return (
                <Page.Panel noPadding color='grey' spread={shouldSpread} key={entry.codeId}>
                  <Posts items={props.news} basePath={newsPage.path} labelKey='date' />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'slOurservices') {
              return (
                <Page.Panel key={entry.codeId} noPadding color='grey' spread={shouldSpread}>
                  <MembershipSelectorCard membershipCards={membershipCards} membershipHeader={membershipHeader} />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'calculator') {
              return (
                <Page.Panel key={entry.id} color='grey' noPadding spread={shouldSpread}>
                  <Calculator />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'newCalculator') {
              return (
                <Page.Panel key={entry.codeId} color='grey' noPadding spread={shouldSpread}>
                  <CalculatorFormHomePage />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'calculatorResult') {
              return (
                <Page.Panel key={entry.id} color='grey' noPaddingTop spread={shouldSpread}>
                  <CalculatorResult location={props.location} />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'signupform') {
              return (
                <Page.Panel key={entry.id} color='grey' noPadding spread={shouldSpread}>
                  <SignUp />
                </Page.Panel>
              )
            }
            if (entry.codeId === 'signupformConfirmation') {
              return (
                <Page.Panel key={entry.id} color='grey' spread={shouldSpread}>
                  <SignupConfirmation location={props.location} />
                </Page.Panel>
              )
            }
            break
          default:
            console.warn('ContentfulPage was called with an unhandled block type')
            return null
        }

        return null
      })}
      {children}
      {stories && showSuccessStoriesBottom && <SuccessStories stories={stories} />}
    </Page>
  )
}

export default ContentfulPage
