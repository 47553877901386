import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Text from '../Text'

class TextureHeader extends React.Component {
  componentDidMount () {
    // objectFit fallback for IE and Edge
    if ('objectFit' in document.documentElement.style === false) {
      // checking DOMContentLoaded shouldn't be needed but withouth it tests are failing without it
      // - an error is thrown by Hero.stories.js because `container` is null
      document.addEventListener('DOMContentLoaded', () => {
        var container = this.imageContainer
        var imageSource = container.querySelector('img').src

        container.querySelector('img').style.display = 'none'
        container.style.backgroundSize = 'cover'
        container.style.backgroundImage = 'url(' + imageSource + ')'
        container.style.backgroundPosition = 'center center'
      })
    }
  }

  render () {
    const {
      panelColor,
      headline,
      textColor,
      image,
      panelId
    } = this.props

    return (
      <div
        className={classnames(
          'fb-texture-header',
          {'fb-texture-header--color-accent': panelColor === 'accent'},
          {'fb-texture-header--color-brand': panelColor === 'brand'},
          {'fb-texture-header--text-white': textColor === 'white'}
        )}
        id={panelId}
      >
        {image && (
          <div className='fb-texture-header__image' ref={node => (this.imageContainer = node)}>
            {image}
          </div>
        )}
        <div className='fb-texture-header__overlay'>
          {headline && (
            <Text.TextureHeading
              tag='h1'
              color={textColor}
              className='fb-texture-header__title'
            >
              {headline}
            </Text.TextureHeading>
          )}
        </div>
      </div>
    )
  }
}

TextureHeader.propTypes = {
  panelColor: PropTypes.oneOf(['accent', 'brand']),
  textColor: PropTypes.oneOf(['white', 'black']),
  /**
   * Optional hero image.
   */
  image: PropTypes.node,
  /**
   * Heading text.
   */
  headline: PropTypes.string.isRequired
}

TextureHeader.defaultProps = {
  panelColor: 'accent',
  textColor: 'black'
}

export default TextureHeader
