const _last = require('lodash/last')
const _pick = require('lodash/pick')

const {
  demand,
  supply,
  purchases,
  openingStock,
  costOfStockSold,
  closingStockForShelfLife,
  writeOffs,
  closingStock
} = require('./formulas')

const months = require('../common/months')
const returnProps = ['demand', 'supply', 'purchases', 'openingStock', 'costOfStockSold', 'writeOffs', 'closingStock']

module.exports = class SupplyAndDemand {
  constructor (props) {
    this.inputs = props.base
    this.startInputs = props.startInputs
    this.demand = [0]
    this.supply = [0]
    this.purchases = [0]
    this.openingStock = [0]
    this.costOfStockSold = [0]
    this.writeOffs = [0]
    this.closingStock = [0]
  }

  calcDemand () {
    this.demand.push(demand({
      demandVariance: this.inputs.demandVariance,
      salesPotential: this.startInputs.avgMonthSales,
      retailMarkup: this.inputs.financing.retailMarkup
    }))
  }

  calcSupply () {
    const closingStock = this.closingStock[this.closingStock.length - 1]
    this.supply.push(supply({
      inventoryAccuracy: this.inputs.operations.inventoryAccuracy,
      salesPotential: this.startInputs.avgMonthSales,
      retailMarkup: this.inputs.financing.retailMarkup,
      closingStock
    }))
  }

  calcPurchases () {
    this.purchases.push(purchases(_last(this.supply)))
  }

  calcOpeningStock () {
    const closingStock = this.closingStock[this.closingStock.length - 1]
    this.openingStock.push(openingStock({
      purchases: _last(this.supply),
      closingStock
    }))
  }

  calcCostOfStockSold () {
    const demand = _last(this.demand)
    const openingStock = _last(this.openingStock)
    this.costOfStockSold.push(costOfStockSold({
      openingStock,
      demand
    }))
  }

  calcClosingStock () {
    const openingStock = _last(this.openingStock)
    const costOfStockSold = _last(this.costOfStockSold)
    this.closingStock.push(closingStock({
      openingStock,
      costOfStockSold
    }))
  }

  calcClosingStockForShelfLife () {
    const openingStock = this.openingStock
    const costOfStockSold = this.costOfStockSold
    const writeOffs = this.writeOffs
    this.closingStock = closingStockForShelfLife({
      openingStock,
      costOfStockSold,
      writeOffs
    })
  }

  calcWriteOffs () {
    const calcWriteOffs = writeOffs({
      purchases: this.purchases,
      writeOff: this.inputs.operations.writeOffs
    })
    this.writeOffs = months.map((_) => calcWriteOffs)
    this.writeOffs.unshift(0)
  }

  result () {
    return _pick(this, returnProps)
  };
}
