import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ToolTip from './ToolTip'
import NumberFormat from 'react-number-format'

const defaultOnChange = () => null

const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/
const phoneNumberRegex = /^[0-9+ ]+$/

class TextInput extends React.Component {
  static propTypes = {
    /**
     * An optional className to apply to the element
     */
    className: PropTypes.string,

    /**
     * An optional type to pass to the element
     */
    type: PropTypes.string,

    /**
     * The error state
     */
    hasError: PropTypes.bool,

    /**
     * Runs on input changes, returns the text value (not the event)
     */
    onValueChange: PropTypes.func,

    /**
     * Runs on input change, returns whole event
     */
    onChange: PropTypes.func,

    /**
     * use to ref the input field
     */
    getInputRef: PropTypes.func,

    /**
     * The input value
     */
    value: PropTypes.string,

    /**
     * Number of rows to display
     */
    rows: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    /**
     * Align value on the right side
     */
    textAlign: PropTypes.oneOf(['left', 'right'])
  }

  static defaultProps = {
    className: '',
    type: 'text',
    hasError: false,
    onChange: defaultOnChange,
    onValueChange: null,
    getInputRef: () => null,
    // Set to '' because we want React to know this a controlled component
    value: '',
    rows: 1,
    textAlign: 'left'
  }

  state = {
    hasError: false,
    validationError: ''
  }

  handleChange = e => {
    const { onValueChange, onChange, name, email, required } = this.props
    let newValue = e.target.value

    if (typeof onValueChange === 'function') {
      let hasError = (required && !newValue)

      if (email && (newValue && !emailRegex.test(newValue.trim()))) {
        hasError = true
      }

      const isPhoneNumber = name === 'phoneNumber'
      if (isPhoneNumber && (newValue && !phoneNumberRegex.test(newValue.trim()))) {
        hasError = true
      }

      onValueChange({ [name]: { value: newValue, hasError } })
      this.setState({ hasError })
    }

    if (typeof onChange === 'function') {
      e.persist()
      onChange(e)
    }
  }

  render () {
    const {
      className,
      type,
      value,
      rows,
      textAlign,
      onValueChange,
      onChange,
      getInputRef,
      label,
      sublabel,
      inputLabelLeft,
      inputLabelRight,
      toolTip,
      onlyNumber,
      required,
      errorMessage,
      error,
      ...otherProps
    } = this.props

    const { hasError } = this.state
    const props = {
      className: classnames(
        'fb-input',
        { 'fb-input--align-right': textAlign === 'right' },
        { 'fb-input--has-error': hasError || error },
        { 'with-left-label': !!inputLabelLeft },
        className
      ),
      'data-qa': this.props.dataQa || this.props.name,
      onChange: this.handleChange,
      rows,
      value,
      type,
      ref: getInputRef,
      ...otherProps
    }

    const labelProps = label
      ? {
        className: classnames(
          'fb-input__label'
        )
      }
      : {}

    const sublabelProps = label
      ? {
        className: classnames(
          'fb-input__sublabel'
        )
      }
      : {}

    const leftInputLabelProps = inputLabelLeft
      ? {
        className: classnames(
          'fb-input__label-inner fb-input__label-inner--left'
        )
      }
      : {}

    const rightInputLabelProps = inputLabelRight
      ? {
        className: classnames(
          'fb-input__label-inner fb-input__label-inner--right'
        )
      }
      : {}

    return (
      React.createElement('div', { className: 'fb-input__wrapper' },
        label
          ? React.createElement('label', labelProps,
            toolTip ? <ToolTip text={toolTip} /> : null,
            React.createElement('div', {}, label)
          )
          : null,
        sublabel
          ? React.createElement('div', sublabelProps, sublabel)
          : null,
        onlyNumber ? <NumberFormat thousandSeparator {...props} /> : React.createElement('input', props),
        ((hasError || error) && errorMessage) ? React.createElement('span', { className: 'fb-input__error-text' }, errorMessage) : null,
        inputLabelLeft ? React.createElement('span', leftInputLabelProps, inputLabelLeft) : null,
        inputLabelRight ? React.createElement('span', rightInputLabelProps, inputLabelRight) : null
      ))
  }
}

export default TextInput
