const formatDate = (date) => {
  if (!date || date === '') {
    return ''
  }
  const dateObj = new Date(date)
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
  const [{ value: mo },, { value: da },, { value: ye }] = dateTimeFormat.formatToParts(dateObj)

  return `${da} ${mo} ${ye}`.toUpperCase()
}

export default formatDate
