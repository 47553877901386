import React from 'react'

const Quote = () =>
  <span className='icon'>
    <svg xmlns='http://www.w3.org/2000/svg' width='38' height='43' viewBox='0 0 43.7 38.55' fill='#46dccd'>
      <path d='M16.8,38.55V20.4h-9V16.8c0-5,1.35-10.5,10.35-10.5V0C7.35,0,0,6.15,0,16.5v22Zm25.55,0V20.4H33.2V16.8c0-5,1.5-10.5,10.5-10.5V0C32.9,0,25.55,6.15,25.55,16.5v22Z' />
    </svg>
  </span>

export default Quote
