import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import Text from '../Text'
import UppercaseLabel from '../UppercaseLabel'
import FieldBrandIcon from '../../icons/FieldBrandIcon'

const CardContent = ({
  children,
  label,
  headline,
  imageUrl,
  imageFullWidth,
  linkUrl,
  buttonText,
  buttonLink,
  buttonProps,
  invert
}) => (
  <div className='fb-card-content'>
    <div className='fb-card-content__header'>
      {label && <UppercaseLabel labelText={label} className='fb-card-content__label' />}

      {headline && <Text.Heading
        size='largish'
        className='fb-card-content__heading'
      >
        {headline}
      </Text.Heading>}

      {!imageUrl && <FieldBrandIcon color={invert ? 'accent' : 'black'} className='fb-card-content__icon' width='8px' />}

      {imageUrl &&
        <div className={classnames(
          {'fb-card-content__image--full-width': imageFullWidth},
          'fb-card-content__image'
        )}>
          <img alt={headline} src={imageUrl} />
        </div>
      }
    </div>
    <div className='fb-card-content__body'>
      <Text size='body' className='fb-card-content__text'>
        {children}
      </Text>
      {buttonLink && <Button href={buttonLink} {...buttonProps} color={invert ? 'accent' : 'brand'}>{buttonText}</Button>}
    </div>
  </div>
)

CardContent.propTypes = {
  /**
   * Card body
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional small uppercase label above the headline.
   */
  label: PropTypes.string,
  /**
   * Card headline.
   */
  headline: PropTypes.string,
  /**
   * Image url. When there's an image, there will be no brand icon below the heading.
   */
  imageUrl: PropTypes.string,
  /**
   * Determines if image be the full width of the card
   */
  imageFullWidth: PropTypes.bool,
  /**
   * Generic link url.
   */
  linkUrl: PropTypes.string,
  /**
   * Button link url.
   */
  buttonLink: PropTypes.string,
  /**
    Other props for button, e.g. target='_blank'
   */
  buttonProps: PropTypes.object,
  /**
   * Button text.
   */
  buttonText: PropTypes.string,
  /**
   * Colors of the card are inverted when set to true
   */
  invert: PropTypes.bool
}

CardContent.displayName = 'Card.Content'

CardContent.defaultProps = {
  imageFullWidth: true
}

export default CardContent
