import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Card from './Card'
import styles from './jobPosts.module.scss'
import formatDate from '../utilities/formatDate/formatDate'

const Posts = ({items, basePath, labelKey}) => {
  return (
    <div className={styles.jobPosts}>
      {items.map(({ node }) => {
        let label = get(node, labelKey)
        if (labelKey === 'date') {
          label = formatDate(label)
        }

        const shouldInvert = node.category === 'Fulfillment Partners' || node.featured

        return (
          <div key={node.title} className={styles.jobPosts__entry}>
            <Card
              invert={shouldInvert}
              spread
            >
              <Card.Content
                headline={node.title}
                label={label}
                buttonLink={`/${basePath}/${node.slug}`}
                buttonText='See details'
              >
                {(node.teaser && node.teaser.childMarkdownRemark) && (
                  <div
                    dangerouslySetInnerHTML={{ __html: node.teaser.childMarkdownRemark.html }}
                  />
                )}
              </Card.Content>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

Posts.defaultProps = {
  items: []
}

Posts.propTypes = {
  /**
   * Path from the config file that will be used to direct to a proper url when the button is clicked.
   */
  basePath: PropTypes.string.isRequired,

  /**
   * The items to be displayed.
   */
  items: PropTypes.array,

  /**
   * Contentful field that should be displayed as a label. Currently used: 'location' for jobs and 'date' for news
   */
  labelKey: PropTypes.string
}

export default Posts
