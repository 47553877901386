import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Radio from './Radio'

const StatelessRadioGroup = ({
  className,
  legend,
  options,
  inRows,
  disabled,
  defaultValue,
  value,
  withUppercase,
  onValueChange,
  hasError,
  errorMessage,
  error,
  ...otherProps
}) => (
  <fieldset
    className={classnames(
      'fb-options-group',
      {'fb-options-group--in-rows': inRows},
      className
    )}
  >
    <div className='fb-options-group__wrapper'>
      {legend &&
        <legend className='fb-options-group__legend'>
          {legend}
        </legend>
      }
      {options && (
        <div className='fb-options-group__options'>
          {options.map(option => {
            const isDisabled = disabled || option.disabled
            const currentValue = value || defaultValue
            const isOptionSelected = currentValue === option.value
            const isError = (hasError || error)
            return (
              <div
                className={`fb-options-group__item ${isError ? '' : 'fb-options-group__item--has-error'}`}
                key={`option-${option.value}`}
              >
                <Radio
                  {...option}
                  isSelected={isOptionSelected}
                  onChange={event => {
                    const nextValue = event.target.value
                    onValueChange(nextValue)
                  }}
                  disabled={isDisabled}
                  withUppercase={withUppercase}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  </fieldset>
)

StatelessRadioGroup.displayName = 'RadioGroup.Stateless'

class RadioGroup extends Component {
  static Stateless = StatelessRadioGroup

  static propTypes = {
    /**
     * Label describing the group, e.g. "Sort list by:"
     */
    label: PropTypes.node,

    /**
     * Options to be rendered
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ).isRequired,

    /**
     * Pass the value of an option to pre-select it.
     */

    defaultValue: PropTypes.string,
    /**
     * Render each option in an own row no matter the screen size
     */
    inRows: PropTypes.bool,

    /**
     * onValueChange to apply to the whole group, fires when new selection is made, returns the selected value.
     */
    onValueChange: PropTypes.func,

    /**
     * onChange to apply to the whole group, fires when new selection is made, returns the selected value.
     */
    onChange: PropTypes.func,

    /**
     * Allows disabling all options at once
     */
    disabled: PropTypes.bool,

    /**
     * Add extra class
     */
    className: PropTypes.string
  }

  static defaultProps = {
    defaultValue: undefined,
    selectedOption: undefined,
    inRows: false,
    onValueChange: () => null,
    onChange: () => null,
    disabled: false
  }

  constructor (props) {
    super(props)

    this.state = {
      value: this.props.value || this.props.selectedOption
    }
  }

  handleValueChange = nextValue => {
    this.setState({ value: nextValue }, () => {
      const {name} = this.props
      this.props.onValueChange({[name]: { value: nextValue }})
      this.props.onChange(nextValue)
    })
  }

  render () {
    return (
      <StatelessRadioGroup
        {...this.props}
        value={this.state.value}
        onValueChange={this.handleValueChange}
      />
    )
  }
}

StatelessRadioGroup.defaultProps = RadioGroup.defaultProps
StatelessRadioGroup.propTypes = RadioGroup.propTypes

export default RadioGroup
