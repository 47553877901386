const _pick = require('lodash/pick')
const {
  missedSales,
  accountPayable,
  map,
  prePaidRent,
  netWorkingCapital,
  netMargin,
  liabilities,
  equity,
  debt,
  assets,
  totalCash,
  changeInAccountPayable,
  changeInStock,
  changesInRentPayment,
  accountPayableForShelfLife
} = require('./formulas')

const months = require('../common/months')

const returnProps = [
  'netIncome',
  'cashFromFinancing',
  'changeInCompliancePrepayment',
  'changeInRentPayment',
  'changeInStock',
  'changeInAccountPayable',
  'totalCash'
]

const balanceSheetProps = [
  'prePaidCompliance',
  'prePaidRent',
  'stock',
  'accountPayable',
  'cashOnHand',
  'assets',
  'debt',
  'equity',
  'liabilities'
]

const metricsProps = [
  'netMargin',
  'netWorkingCapital',
  'missedSales'
]

module.exports = class ProfitAndLoss {
  constructor (props) {
    this.inputs = props.base
    this.startInputs = props.startInputs
    this.supplyAndDemand = props.supplyAndDemand
    this.profitAndLoss = props.profitAndLoss
    this.changeInCompliancePrepayment = []
    this.changeInRentPayment = []
    this.changeInAccountPayable = []
    this.changeInStock = []
    this.cashFromFinancing = map(0, this.inputs.financing.principal)
    this.prePaidCompliance = []
    this.prePaidRent = []
    this.accountPayable = []
    this.totalCash = []
    this.assets = []
    this.cashOnHand = []
    this.debt = []
    this.equity = []
    this.liabilities = []
    this.netMargin = []
    this.netWorkingCapital = []
    this.missedSales = []
    this.stock = []
    this.netIncome = []
  }

  calculateNetIncome (netIncome) {
    this.netIncome = netIncome
    // this.profitAndLoss.netIncome;
  }

  calculateStock () {
    this.stock = this.supplyAndDemand.closingStock
  }

  calculateStockForShelfLife () {
    this.stock = ['December', ...months].map((_) => 0)
  }

  calcAccountPayable () {
    this.accountPayable = accountPayable({
      purchases: this.supplyAndDemand.purchases,
      tradeCredit: this.inputs.financing.tradeCredit
    })
  }

  calcAccountPayableForShelfLife () {
    this.accountPayable = accountPayableForShelfLife({
      costOfStockSold: this.profitAndLoss.costOfStockSold
    })
  }

  calculate () {
    this.prePaidCompliance = [this.inputs.runningCosts.compliance]
    for (const [i] of months.entries()) {
      const value = this.prePaidCompliance[i] - (this.prePaidCompliance[0] / 12)
      this.prePaidCompliance.push(Math.round(value))
    }

    this.prePaidRent = prePaidRent(this.inputs.runningCosts.rent)

    this.changeInCompliancePrepayment = [this.inputs.runningCosts.compliance]
    for (const [i] of months.entries()) {
      const index = months[i + 1] ? i + 1 : null
      const value = (this.prePaidCompliance[index] ? this.prePaidCompliance[index] : 0) - this.prePaidCompliance[i]
      this.changeInCompliancePrepayment.push(Math.abs(Math.round(value)))
    }

    this.changeInRentPayment = changesInRentPayment({
      rent: this.inputs.runningCosts.rent,
      prePaidRent: this.prePaidRent
    })

    this.changeInStock = changeInStock(this.stock)
    this.changeInAccountPayable = changeInAccountPayable(this.accountPayable)

    this.totalCash = totalCash({
      changeInCompliancePrepayment: this.changeInCompliancePrepayment,
      changeInRentPayment: this.changeInRentPayment,
      changeInStock: this.changeInStock,
      avgMonthSales: this.startInputs.avgMonthSales,
      cashFromFinancing: this.cashFromFinancing,
      changeInAccountPayable: this.changeInAccountPayable,
      netIncome: this.netIncome
    })

    this.cashOnHand = this.totalCash

    this.assets = assets({
      stock: this.stock,
      prePaidCompliance: this.prePaidCompliance,
      prePaidRent: this.prePaidRent,
      cashOnHand: this.cashOnHand
    })

    this.debt = debt({
      cumulative: this.inputs.financing.cumulative,
      debtService: this.profitAndLoss.debtService
    })

    this.equity = equity({
      assets: this.assets,
      debt: this.debt,
      accountPayable: this.accountPayable
    })

    this.liabilities = liabilities({
      equity: this.equity,
      debt: this.debt,
      accountPayable: this.accountPayable
    })

    const { income, costOfSales, expenses } = this.profitAndLoss

    this.netMargin = netMargin({
      income,
      costOfSales,
      expenses
    })

    this.netWorkingCapital = netWorkingCapital({
      debt: this.debt,
      accountPayable: this.accountPayable,
      assets: this.assets
    })

    this.missedSales = missedSales({
      demand: this.supplyAndDemand.demand,
      costOfStockSold: this.supplyAndDemand.costOfStockSold,
      retailMarkup: this.inputs.financing.retailMarkup
    })

    return {
      cashFlow: _pick(this, returnProps),
      balanceSheet: _pick(this, balanceSheetProps),
      metrics: _pick(this, metricsProps)
    }
  }
}
