const _last = require('lodash/last')
const _sum = require('lodash/sum')
const _mean = require('lodash/mean')

module.exports = (props) => {
  const { baseLine, shelfLife, slMemberFee } = props
  const costOfShelfLife = slMemberFee * 12
  // calc Equity
  const equityValue = {
    baseLine: _last(baseLine.balanceSheet.equity),
    shelfLife: _last(shelfLife.balanceSheet.equity),
    diff: 0
  }
  equityValue.diff = equityValue.shelfLife - equityValue.baseLine

  // calc Net profit
  const netProfit = {
    baseLine: _sum(baseLine.profitAndLoss.netIncome),
    shelfLife: _sum(shelfLife.profitAndLoss.netIncome),
    diff: 0
  }
  netProfit.diff = netProfit.shelfLife - netProfit.baseLine

  // calc netWorkingCapital
  const netWorkingCapital = {
    baseLine: (_last(baseLine.balanceSheet.cashOnHand)) - (_last(baseLine.balanceSheet.accountPayable)),
    shelfLife: (_last(shelfLife.balanceSheet.cashOnHand)) - (_last(shelfLife.balanceSheet.accountPayable)),
    diff: 0
  }
  netWorkingCapital.diff = netWorkingCapital.shelfLife - netWorkingCapital.baseLine

  // calc avg cash on hand
  const avgCashOnHand = {
    baseLine: _mean(baseLine.balanceSheet.cashOnHand),
    shelfLife: _mean(shelfLife.balanceSheet.cashOnHand),
    diff: 0
  }
  avgCashOnHand.diff = avgCashOnHand.shelfLife - avgCashOnHand.baseLine

  // calc total missed sales
  const missedSales = {
    baseLine: -(_sum(baseLine.metrics.missedSales)),
    shelfLife: -(_sum(shelfLife.metrics.missedSales)),
    diff: 0
  }
  missedSales.diff = missedSales.shelfLife - missedSales.baseLine

  // calc total Write Off
  const totalWriteOffs = {
    baseLine: -(_sum(baseLine.profitAndLoss.stockWriteOffs)),
    shelfLife: _sum(shelfLife.profitAndLoss.stockWriteOffs),
    diff: 0
  }
  totalWriteOffs.diff = totalWriteOffs.shelfLife - totalWriteOffs.baseLine

  const gainFromShelfLife = netProfit.diff

  const annualReturn = (gainFromShelfLife / costOfShelfLife) * 100

  const percentage = {
    cashOnHand: Math.round((_last(baseLine.balanceSheet.cashOnHand) * 100) / _last(shelfLife.balanceSheet.cashOnHand)),
    saving: Math.round((missedSales.shelfLife * 100) / missedSales.baseLine),
    netIncome: Math.round((netProfit.shelfLife * 100) / netProfit.baseLine)
  }

  return {
    costOfShelfLife,
    gainFromShelfLife,
    equityValue,
    netProfit,
    netWorkingCapital,
    avgCashOnHand,
    missedSales,
    totalWriteOffs,
    annualReturn,
    percentage
  }
}
