import React from 'react'
import Img from 'gatsby-image'

import Text from './Text'
import styles from './peopleWall.module.scss'

const PeopleWall = ({people}) => {
  return (
    <div className={styles.peopleWall}>
      {people.map(({ node }) => {
        const {
          firstName,
          lastName,
          image,
          link
        } = node

        const name = [firstName, lastName].join(' ')

        let tag = 'div'
        let tagProps = {
          className: styles.peopleWall__entry,
          key: `${node.firstName}-${node.lastName}`
        }

        if (link) {
          tag = 'a'
          tagProps = {
            ...tagProps,
            href: link,
            target: '_blank'
          }
        }

        return React.createElement(
          tag,
          tagProps,
          <div
            className={styles.peopleWall__ratioWrapper}
          >
            {image && (
              <div className={styles.peopleWall__image}>
                {image.fluid && (
                  <Img alt={name} fluid={image.fluid} />
                )}
              </div>
            )}
            <Text size='small' weight='bold' className={styles.peopleWall__name}>
              {name}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default PeopleWall
