import React from 'react'

import { CALCULATION_DATA } from './constants'
import { prepareCountryOptions, prepareTierOptions } from './utils'

import Box from '../../Box'
import Button from '../../Button'
import RadioGroup from './../../RadioGroup'
import Text from './../../Text'

class CalculatorSimple extends React.PureComponent {
  constructor (props) {
    super(props)

    const initialCountry = 'nigeria'
    const countryData = CALCULATION_DATA[initialCountry]
    const tierOptions = prepareTierOptions(countryData)
    const countriesAvail = Object.keys(CALCULATION_DATA)
    const countriesOptions = prepareCountryOptions(countriesAvail)

    this.state = {
      loading: true,
      country: initialCountry,
      countriesOptions: countriesOptions,
      tier: 'tier1',
      tierOptions: tierOptions,
      error: false
    }
  }

  changeCountry (newCountry) {
    const { country } = this.state
    if (country !== newCountry) {
      const countryData = CALCULATION_DATA[newCountry]
      const newTierOptions = prepareTierOptions(countryData)
      this.setState({
        country: newCountry,
        tierOptions: newTierOptions,
        tier: 'tier1'
      })
    }
  }

  render () {
    const { country, countriesOptions, tierOptions, tier } = this.state
    const { layout } = this.props

    return (
      <div className={`fb-calculator ${layout ? `fb-calculator--${layout}` : ''}`}>
        <Box layout={layout} tight>
          <Box.Body>
            <Text.Heading size='medium' color='brand'>
              Tell us a bit about your pharmacy to see how much you could save
            </Text.Heading>
            <RadioGroup
              className='fb-calculator__options-group'
              legend='Where is your pharmacy?'
              options={countriesOptions}
              selectedOption={country}
              onChange={currentCountry => {
                this.changeCountry(currentCountry)
              }}
            />
            <RadioGroup
              className='fb-calculator__options-group'
              legend='What are your typical monthly sales?'
              options={tierOptions}
              selectedOption={tier}
              onChange={currentTier => {
                this.setState({
                  tier: currentTier
                })
              }}
              inRows
            />
            <Button
              link={`/calculator-result/`}
              state={{ country: country, tier: tier }}
              className='fb-calculator__button'
            >
              Calculate
            </Button>
          </Box.Body>
        </Box>
      </div>
    )
  }
}

export default CalculatorSimple
