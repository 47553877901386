import React from 'react'
import { navigate } from 'gatsby'
import get from 'lodash/get'

import { CALCULATION_DATA, COUNTRY_CURRENCY_SYMBOL } from './constants'

import Box from '../../Box'
import HeroCta from '../../HeroCta'
import Text from '../../Text'
import CalcResultsExplanation from './CalcResultsExplanation'

class CalcResultsSimple extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      country: '',
      currency: '',
      result: null,
      tier: ''
    }
  }

  componentDidMount () {
    const country = get(this.props, 'location.state.country', null)
    const tier = get(this.props, 'location.state.tier', null)

    if (!country || !tier) {
      return navigate('/')
    }

    const resultData = CALCULATION_DATA[country][tier]
    const currency = COUNTRY_CURRENCY_SYMBOL[country]

    this.setState({
      country: country,
      currency: currency,
      result: resultData,
      tier: tier
    })
  }

  render () {
    const { result, country, currency, tier } = this.state

    if (!result) {
      return <div>No data</div>
    }

    return (
      <Box rounded background='white' layout='block'>
        <Box.Body className='fb-calculation-result'>
          <CalcResultsExplanation
            country={country}
            tier={tier}
          />
          <div className='fb-calculation-result__data'>
            <div className='fb-calculation-result__data-row'>
              <span className='fb-calculation-result__data-value'>
                {currency} {result.extraEarnings}
                <span className='fb-calculation-result__arrow'>↑</span>
              </span>
              <Text size='xlarge'>
                Extra earnings each year
              </Text>
            </div>
            <div className='fb-calculation-result__data-row'>
              <span className='fb-calculation-result__data-value'>
                {result.cashOnHand}%
                <span className='fb-calculation-result__arrow'>↑</span>
              </span>
              <Text size='xlarge'>Increase in cash on hand</Text>
            </div>
            <img
              className='fb-calculation-result__image'
              src='/assets/images/SL-faux-chart.svg' />
          </div>
          <Text
            size='medium' className='fb-calculation-result__info'
          >
            Take your business to the next level with Shelf Life. Stores your size can see big gains from improvements in on-shelf availability, avoiding expensive loans for inventory, and saying goodbye to costly expiries.
          </Text>
          <Text
            size='medium' className='fb-calculation-result__info'
          >
            Grow your pharmacy, realize this and more by creating a free Shelf Life account
          </Text>
          <HeroCta
            color='brand'
            url='/signup/'
            buttonText='Create a free Shelf Life account'
          />
        </Box.Body>
      </Box>
    )
  }
}

export default CalcResultsSimple
