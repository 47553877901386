import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Checkmark from '../../icons/Checkmark'
import Clock from '../../icons/Clock'

const ProgressSteps = (props) => {
  const {
    steps,
    activeStep,
    activeStepPending,
    className
  } = props

  const stepKeys = Object.keys(steps)
  const activeIndex = stepKeys.indexOf(activeStep)

  return (
    <ul className='fb-progress-steps'>
      {stepKeys.map((step, index) => {
        const stepActive = step === activeStep
        const stepDone = index < activeIndex
        const currentStep = steps[step]

        return (
          <li
            key={step}
            className={classnames(
              'fb-progress-steps__step',
              {'fb-progress-steps__step--done': stepDone},
              {'fb-progress-steps__step--active': stepActive},
              className
            )}
          >
            <span
              className={classnames(
                'fb-progress-steps__marker',
                {'fb-progress-steps__marker--done': (stepDone || (stepActive && activeStepPending))},
                {'fb-progress-steps__marker--active': stepActive && !activeStepPending}
              )}
            >
              {(stepDone || (stepActive && activeStepPending)) &&
                <span className='fb-progress-steps__marker-icon'>
                  <span className='icon-container'>
                    <Checkmark
                      className='icon'
                      size='1em'
                    />
                  </span>
                </span>
              }
            </span>
            <span>
              {currentStep.description}
              {stepActive && activeStepPending && currentStep.infoPending && (
                <span className='fb-progress-steps__substep'>
                  <span className='fb-progress-steps__substep-icon'>
                    <span className='icon-container'>
                      <Clock
                        className='icon'
                        size='1em'
                      />
                    </span>
                  </span>
                  {currentStep.infoPending}
                </span>
              )}
              {stepDone && currentStep.infoCompleted && (
                <span className='fb-progress-steps__substep fb-progress-steps__substep--done'>
                  <span className='fb-progress-steps__substep-icon fb-progress-steps__substep-icon--done'>
                    <Checkmark />
                  </span>
                  {currentStep.infoCompleted}
                </span>
              )}
            </span>
          </li>
        )
      }
      )}
    </ul>
  )
}

ProgressSteps.propTypes = {
  /**
   * An object containing steps
   * {
   *   stepName: {
   *     description: 'text',
   *      infoPending: 'optional text',
   *      infoCompleted: 'optional text',
   *   },
   *   stepName2: {
   *     ...
   *   }
   * }
   */
  steps: PropTypes.object,
  /**
   * A key of active step
   */
  activeStep: PropTypes.string,
  /**
   * A key of active step
   */
  activeStepPending: PropTypes.bool,
  /**
   * In case you want to extend the default class names without overriding the default look.
   * e.g adding custom padding class `className='add-padding'`
   */
  className: PropTypes.string
}

ProgressSteps.defaultProps = {
  steps: {},
  activeStep: undefined,
  className: undefined,
  activeStepPending: false
}

export default ProgressSteps
