import React from 'react'
import StatsItem from './StatsItem'

const StatsBlock = ({stats, color}) => {
  return stats ? (
    <div className='fb-stats-block'>
      {stats.map(statItem => (
        <div className='fb-stats-block__entry' key={`${statItem.keyNumber}${statItem.legend}`}>
          <StatsItem {...statItem} onColor={color} />
        </div>
      ))}
    </div>
  ) : null
}

export default StatsBlock
