import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Checkmark from '../../icons/Checkmark'

const StatelessCheckbox = props => {
  const {
    className,
    id,
    name,
    checked,
    label,
    subLabel,
    hasError,
    onChange,
    ...otherProps
  } = props

  const inputId = id || name

  // Because we're hiding the input element
  // we have to click the label in testing
  const dataQa = otherProps['data-qa']
  delete otherProps['data-qa']

  return (
    <label
      htmlFor={inputId}
      data-qa={dataQa}
      className={classnames(
        'fb-checkbox',
        {'fb-checkbox--is-checked': checked},
        {'fb-checkbox--has-error': hasError},
        className
      )}
    >
      <span className='fb-checkbox__content'>
        <input
          {...otherProps}
          className={classnames(
            'fb-checkbox__input',
            'visually-hidden'
          )}
          type='checkbox'
          checked={checked}
          id={inputId}
          name={name}
          aria-invalid={hasError}
          onChange={onChange}
        />
        <span className='fb-checkbox__faux-box' aria-hidden='true'>
          <span className='fb-checkbox__faux-check'>
            <Checkmark />
          </span>
        </span>
        <span className='fb-checkbox__label'>
          {label}
          {subLabel &&
            <span className='fb-checkbox__sub-label'>
              {subLabel}
            </span>
          }
        </span>
      </span>
    </label>
  )
}

StatelessCheckbox.propTypes = {
  /**
   * checkbox label, e.g. I accept the terms and conditions.
   */
  label: PropTypes.string,
  /**
   * checkbox sub label (line 2).
   */
  subLabel: PropTypes.string,
  /**
   * has validation error
   */
  hasError: PropTypes.bool,
  /**
  * handler function which is called on change (passing the DOM event)
  */
  onChange: PropTypes.func
}

StatelessCheckbox.defaultProps = {
  label: undefined,
  subLabel: undefined,
  hasError: false,
  onChange: () => {},
  checked: false
}

StatelessCheckbox.displayName = 'Checkbox.Stateless'

class Checkbox extends React.Component {
  static Stateless = StatelessCheckbox

  static propTypes = {
    /**
     * field name, e.g. newsletter, if checkbox is standalone
     */
    name: PropTypes.string,

    /**
     * checkbox label, e.g. I accept the terms and conditions.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,

    /**
     * checkbox sub label (line 2).
     */
    subLabel: PropTypes.string,

    /**
     * Fired when user checks/unchecks (returns checked state: true/false)
     */
    onValueChange: PropTypes.func,

    /**
     * has validation error
     */
    hasError: PropTypes.bool,

    /**
     * additional class names you want to add to the wrapping label
     */
    className: PropTypes.string
  }

  static defaultProps = {
    name: undefined,
    subLabel: undefined,
    className: undefined,
    onChange: () => null,
    onValueChange: () => null,
    hasError: false
  }

  static DisplayName = 'Checkbox'

  state = {
    isChecked: this.props.defaultChecked
  }

  handleValueChange = e => {
    e.persist()
    const { checked: isChecked } = e.target
    const { onValueChange, onChange } = this.props

    this.setState({ isChecked }, () => {
      onValueChange(this.state.isChecked)
      onChange(e)
    })
  }

  render () {
    const {
      // Block props from stateless checkbox that we are handling here
      defaultChecked,
      onValueChange,
      ...otherProps
    } = this.props

    return (
      <StatelessCheckbox
        {...otherProps}
        checked={this.state.isChecked}
        onChange={this.handleValueChange}
      />
    )
  }
}

export default Checkbox
