import React from 'react'

const Bubble = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.75 42.75'>
    <circle fill='#46dccd' cx='21.38' cy='21.38' r='19.88' />
    <circle fill='#46dccd' cx='20.5' cy='20.5' r='7' />
    <g>
      <path fill='#fff' data-name='Comment' className='cls-3' d='M19.38,11.86a8.57,8.57,0,0,0-6.56,5.36,8.92,8.92,0,0,0,1.09,8.53L12,27.89a.82.82,0,0,0-.14.87.77.77,0,0,0,.72.49h7.84A8.8,8.8,0,0,0,26.4,14,8.4,8.4,0,0,0,19.38,11.86Z' />
    </g>
  </svg>
)

export default Bubble
