const SupplyAndDemand = require('./supplyAndDemand')
const ProfitAndLoss = require('./profitAndLoss')
const CashFlow = require('./cashFlow')
const generateReportData = require('./report')
const charts = require('./charts')
const { retailMarkupForShelfLife, roundAll, startingCash } = require('./formulas')
const months = require('../common/months')

const calculator = {
  baseLine: (props) => {
    const { base, startInputs } = props

    const supplyAndDemand = new SupplyAndDemand({
      base,
      startInputs
    })
    months.forEach(() => {
      supplyAndDemand.calcDemand()
      supplyAndDemand.calcSupply()
      supplyAndDemand.calcPurchases()
      supplyAndDemand.calcOpeningStock()
      supplyAndDemand.calcCostOfStockSold()
      supplyAndDemand.calcClosingStock()
    })
    supplyAndDemand.calcWriteOffs()
    const supplyAndDemandResult = supplyAndDemand.result()

    const profitAndLoss = new ProfitAndLoss({
      base,
      startInputs: startInputs,
      supplyAndDemand: supplyAndDemandResult
    })
    profitAndLoss.calculateStockWriteOffs()
    profitAndLoss.calculateCashSalesAndIncome()
    profitAndLoss.calculateElse()
    profitAndLoss.calculateExpenses()
    profitAndLoss.calculateNetIncome()
    const profitAndLossCalc = profitAndLoss.result()

    const cashFlow = new CashFlow({
      base: base,
      startInputs: startInputs,
      supplyAndDemand: supplyAndDemandResult,
      profitAndLoss: profitAndLossCalc
    })
    cashFlow.calculateNetIncome(profitAndLoss.netIncome)
    cashFlow.calculateStock()
    cashFlow.calcAccountPayable()
    const cashFlowCalc = cashFlow.calculate()

    const result = {
      supplyAndDemand: supplyAndDemandResult,
      profitAndLoss: profitAndLossCalc,
      cashFlow: cashFlowCalc.cashFlow,
      balanceSheet: cashFlowCalc.balanceSheet,
      metrics: cashFlowCalc.metrics
    }

    return roundAll(result)
  },
  shelfLife: (props) => {
    const { base, startInputs, baseLine } = props
    base.financing.retailMarkup = retailMarkupForShelfLife({
      salesPotential: startInputs.avgMonthSales,
      retailMarkup: baseLine.inputs.financing.retailMarkup,
      slPrice: base.financing.sLPricing
    })
    const supplyAndDemand = new SupplyAndDemand({
      base,
      startInputs
    })
    supplyAndDemand.demand = baseLine.supplyAndDemand.demand
    months.forEach(() => {
      supplyAndDemand.calcSupply()
      supplyAndDemand.calcPurchases()
      supplyAndDemand.calcOpeningStock()
      supplyAndDemand.calcCostOfStockSold()
    })
    supplyAndDemand.calcWriteOffs()
    supplyAndDemand.calcClosingStockForShelfLife()
    const supplyAndDemandResult = supplyAndDemand.result()

    const profitAndLoss = new ProfitAndLoss({
      base,
      startInputs: startInputs,
      supplyAndDemand: supplyAndDemandResult
    })
    profitAndLoss.calculateStockWriteOffs()
    profitAndLoss.calculateCashSalesAndIncome()
    profitAndLoss.calculateElse()
    profitAndLoss.calculateExpenses()
    profitAndLoss.calculateNetIncome()

    const cashFlow = new CashFlow({
      base: base,
      startInputs: startInputs,
      supplyAndDemand: supplyAndDemandResult,
      profitAndLoss: profitAndLoss.result()
    })
    cashFlow.calculateStockForShelfLife()

    profitAndLoss.calculateStockWriteOffsForShelfLife(cashFlow.stock)
    profitAndLoss.calculateExpenses()
    profitAndLoss.calculateNetIncome()

    cashFlow.calculateNetIncome(profitAndLoss.netIncome)

    const profitAndLossCalc = profitAndLoss.result()
    cashFlow.calcAccountPayableForShelfLife()
    const cashFlowCalc = cashFlow.calculate()

    return roundAll({
      supplyAndDemand: supplyAndDemandResult,
      profitAndLoss: profitAndLossCalc,
      cashFlow: cashFlowCalc.cashFlow,
      balanceSheet: cashFlowCalc.balanceSheet,
      metrics: cashFlowCalc.metrics
    })
  },
  report: generateReportData,
  totalCashCart: charts.totalCashCart,
  annualReportChart: charts.annualReportChart,
  savingChart: charts.savingChart,
  slMemberFee: (1750 * 52) / 12,
  startingCash: startingCash
}

const median = values => {
  values.sort((a, b) => a - b)
  const half = Math.floor(values.length / 2)
  const result = values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2.0
  return Math.round(result)
}

module.exports.actions = calculator

exports.calculate = calcConfigs => {
  calcConfigs.shelfLife.financing.sLMemberFee = calculator.slMemberFee

  for (const prop in Object.keys(calcConfigs.startInputs)) {
    calcConfigs.startInputs[prop] = parseInt(calcConfigs.startInputs[prop])
  }

  if (!calcConfigs.startInputs.avgMonthSales) {
    const { bestMonth, worstMonth } = calcConfigs.startInputs
    calcConfigs.startInputs.avgMonthSales = median([parseInt(bestMonth), parseInt(worstMonth)])
  }
  const baseLine = calculator.baseLine({
    base: calcConfigs.baseLine,
    startInputs: calcConfigs.startInputs
  })

  const shelfLife = calculator.shelfLife({
    base: calcConfigs.shelfLife,
    startInputs: calcConfigs.startInputs,
    baseLine: {
      ...baseLine,
      inputs: calcConfigs.baseLine
    }
  })

  const report = calculator.report({
    shelfLife,
    baseLine,
    slMemberFee: calcConfigs.shelfLife.financing.sLMemberFee
  })

  const cashOnHandChart = calculator.totalCashCart({
    baseLine: baseLine.cashFlow.totalCash,
    shelfLife: shelfLife.cashFlow.totalCash
  })
  const annualReportChart = calculator.annualReportChart(report)
  const savingChart = calculator.savingChart(report)

  return {
    baseLine,
    shelfLife,
    report,
    cashOnHandChart,
    annualReportChart,
    savingChart
  }
}
