import React, { createRef, Fragment } from 'react'
import Button from '../../Button'
import { median } from './utils'
import { Calculator } from '@fielded/value-calculator'
import Box from '../../Box'
import SignupForm from '../../SignupForm'

const formatNumber = number => parseInt(number.replace(/,/g, ''))

class SLCalculator extends React.Component {
  formRef = createRef()

  state = {
    inputs: [
      {
        label: 'Best Month:',
        name: 'bestMonth',
        required: true,
        onlyNumber: true,
        errorMessage: 'This information is required',
        inputLabelLeft: '₦',
        toolTip: 'On the month of your pharmacy’s highest financial performance, what was the amount?'
      }, {
        label: 'Worst Month:',
        name: 'worstMonth',
        onlyNumber: true,
        errorMessage: 'This information is required',
        required: true,
        inputLabelLeft: '₦',
        toolTip: 'On the month of your pharmacy’s lowest financial performance, what was the amount?'
      }, /* {
        label: 'Starting Cash:',
        name: 'startingCash',
        required: true,
        onlyNumber: true,
        readOnly: true
      }, {
        label: 'Average Monthly Sales:',
        name: 'avgMonthSales',
        required: true,
        onlyNumber: true,
        readOnly: true
      }, */ {
        name: 'avgRetailMarkup',
        required: true,
        onlyNumber: true,
        errorMessage: 'This information is required',
        label: 'Average Retail Markup(%)',
        inputLabelRight: '%'
      }
    ],
    loading: true,
    calcResult: null,
    error: false
  }

  componentDidMount () {
    window.fetch('https://field-value-calculator.s3-eu-west-1.amazonaws.com/config.json')
      .then(res => res.json())
      .then(data => {
        this.setState({ calcConfigs: data, loading: false })
        this.updateForm()
      })
      .catch(err => {
        this.setState({ error: err, loading: false })
      })
  }

  updateForm = formData => {
    const { inputs } = this.state

    if (formData.bestMonth && formData.worstMonth) {
      formData.avgMonthSales = median([formatNumber(formData.bestMonth), formatNumber(formData.worstMonth)])
      formData.startingCash = Math.round(Calculator.actions.startingCash(formData.avgMonthSales))
    }
    const updateInputs = inputs.map(input => {
      input.defaultValue = formData[input.name]
      return input
    })
    this.setState({ inputs: updateInputs })
    this.formRef.current.updateFormInputs(updateInputs)
  }

  calculate = () => {
    const { calcConfigs } = this.state
    const valid = this.formRef.current.validate()
    if (valid) {
      const fields = this.formRef.current.getFields()
      if (fields.bestMonth && fields.worstMonth) {
        fields.avgMonthSales = median([formatNumber(fields.bestMonth), formatNumber(fields.worstMonth)])
        fields.startingCash = Math.round(Calculator.actions.startingCash(fields.avgMonthSales))
      }
      calcConfigs.startInputs = {...fields}
      const result = Calculator.calculate(calcConfigs)
      window.localStorage.setItem('startInputs', JSON.stringify(fields))
      this.props.handleOnResult(result)
    }
  }

  render () {
    const { inputs } = this.state
    const { boxLayout } = this.props
    return (
      <Fragment>
        <Box.Body layout={boxLayout || 'grid'}>
          <SignupForm
            ref={this.formRef}
            currency={this.props.currency}
            onFormUpdate={this.updateForm}
            inputs={inputs}
          />
        </Box.Body>
        <Box.Footer>
          <Button
            onClick={this.calculate}
          >Show me how much I can save
          </Button>
        </Box.Footer>
      </Fragment>
    )
  }
}

export default SLCalculator
