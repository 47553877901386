import React from 'react'

const Arrow = ({
  direction = 'left'
}) => (
  <span className='icon-container'>
    <svg className={`icon icon--direction-${direction}`} width='1em' height='1em' viewBox='0 0 14 14' aria-labelledby='title' aria-hidden='true'>
      <title id='title'>Arrow {direction}</title>
      <path d='M13.1428571,6.38392857 L13.1428571,7.52678571 C13.1428571,7.84226348 13.0461319,8.11160603 12.8526786,8.33482143 C12.6592252,8.55803683 12.4077396,8.66964286 12.0982143,8.66964286 L5.8125,8.66964286 L8.42857143,11.2946429 C8.65476304,11.5089296 8.76785714,11.7767841 8.76785714,12.0982143 C8.76785714,12.4196445 8.65476304,12.6874989 8.42857143,12.9017857 L7.75892857,13.5803571 C7.53868938,13.8005963 7.27083491,13.9107143 6.95535714,13.9107143 C6.64583179,13.9107143 6.37500116,13.8005963 6.14285714,13.5803571 L0.330357143,7.75892857 C0.110117946,7.53868937 1.29896094e-13,7.27083491 1.29896094e-13,6.95535714 C1.29896094e-13,6.64583179 0.110117946,6.37500116 0.330357143,6.14285714 L6.14285714,0.339285714 C6.36904875,0.113094107 6.63987938,-7.10542736e-15 6.95535714,-7.10542736e-15 C7.2648825,-7.10542736e-15 7.53273696,0.113094107 7.75892857,0.339285714 L8.42857143,1 C8.65476304,1.22619161 8.76785714,1.49702223 8.76785714,1.8125 C8.76785714,2.12797777 8.65476304,2.39880839 8.42857143,2.625 L5.8125,5.24107143 L12.0982143,5.24107143 C12.4077396,5.24107143 12.6592252,5.35267746 12.8526786,5.57589286 C13.0461319,5.79910826 13.1428571,6.0684508 13.1428571,6.38392857 Z' transform='translate(6.571429, 6.955357) scale(0.75) rotate(180.000000) translate(-6.571429, -6.955357)' />
    </svg>
  </span>
)

export default Arrow
