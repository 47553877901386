import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Highlighter from 'react-highlight-words'

const TwoPartLabel = ({
  option,
  inputValue,
  className,
  ...otherProps
}) => {
  const label = option.label || option.value
  const isArray = Array.isArray(label)

  const mainLabel = isArray ? label[0] : label
  const subLabel = isArray && label.length > 1
    ? label.slice(1, label.length).join(', ')
    : undefined

  return (
    <span
      className={classnames(
        'fs-select-label',
        className
      )}
      {...otherProps}
    >
      {mainLabel && (
        <span className='fs-select-label__main'>
          <Highlighter
            highlightClassName='fs-select-label__highlight'
            searchWords={[inputValue]}
            textToHighlight={mainLabel.toString()}
          />
        </span>
      )}
      {subLabel && (
        <span className='fs-select-label__sub'>
          <Highlighter
            highlightClassName='fs-select-label__highlight'
            searchWords={[inputValue]}
            textToHighlight={subLabel.toString()}
          />
        </span>
      )}
    </span>
  )
}

TwoPartLabel.propTypes = {
  // This is the select option that gets passed on from react-select
  option: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    value: PropTypes.node
  }).isRequired,
  className: PropTypes.string
}

TwoPartLabel.defaultProps = {
  className: undefined
}

export default TwoPartLabel
