import React from 'react'
import { navigate } from 'gatsby'

import CalculatorForm from './CalculatorForm'
import Box from '../../Box'

class SLCalculator extends React.Component {
  state = {
    loading: true,
    currency: '₦',
    // mockData need get rid of after
    form: {
      bestMonth: 15000000,
      worstMonth: 12000000,
      avgRetailMarkup: 50
    },
    error: false
  }

  setResult = result => {
    navigate(
      '/calculator-result/',
      {
        state: { result }
      }
    )
  }

  changeCurrency (newCurrency) {
    const { currency } = this.state
    if (currency !== newCurrency) {
      this.setState({ currency: newCurrency })
    }
  }

  render () {
    const { currency } = this.state
    const { layout } = this.props
    return (
      <div className={`fb-calculator ${layout ? `fb-calculator--${layout}` : ''}`}>
        <Box layout={layout} tight>
          <Box.Header>
            <div className='fb-currencies'>
              <div
                className={`fb-currency ${currency === '₦' ? 'fb-currency--active' : ''}`}
                onClick={() => this.changeCurrency('₦')}
              >NGN
              </div>
              <div
                className={`fb-currency ${currency === 'K' ? 'fb-currency--active' : ''}`}
                onClick={() => this.changeCurrency('K')}
              >KSH
              </div>
            </div>
          </Box.Header>
          <CalculatorForm
            boxLayout={layout}
            currency={currency}
            handleOnResult={this.setResult}
          />
        </Box>
      </div>
    )
  }
}

export default SLCalculator
