import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UppercaseLabel from '../../UppercaseLabel'
import Text from '../../Text'

const StatsItem = ({
  keyNumber,
  legend,
  label,
  onColor
}) => (
  <div
    className={classnames(
      'fb-stats-item',
      {'fb-stats-item--on-brand': onColor === 'brand'},
      {'fb-stats-item--on-light': onColor === 'grey' || onColor === 'white'}
    )}
  >
    { label && <UppercaseLabel labelText={label} /> }
    <div className='fb-stats-item__key'>
      {keyNumber}
    </div>
    <Text size='body' weight='regular' className='fb-stats-item__legend'>
      {legend}
    </Text>
  </div>
)

StatsItem.propTypes = {
  /**
   * An uppercase label above the number.
   */
  label: PropTypes.string,
  /**
   * Stat info: number.
   */
  keyNumber: PropTypes.string.isRequired,
  /**
   * Stat info: legend.
   */
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * On what color is the item displayed
   */
  onColor: PropTypes.oneOf([undefined, 'brand', 'accent', 'white', 'grey'])
}

StatsItem.defaultProps = {
  onColor: 'brand'
}

export default StatsItem
